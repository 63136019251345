<template>
  <div class="calendar">
    <button class="btn-date" @click="toggleDatePicker">
      <div class="dates">
        <span> Du <mark> {{ period.start | dateFormatFr }}</mark></span>
        <span> Au <mark> {{ period.end | dateFormatFr }}</mark></span>
      </div>
      <v-icon :class="[{close: showDatepickers}, 'chevron']">left-chevron</v-icon>
    </button>
    <div v-if="showDatepickers" class="datepickers">
      <date-picker :value="getStartPeriodValue" @update="updateTmpPeriod({ start: true, value: $event })">
        <div slot="title" class="title">Du</div>
      </date-picker>
      <date-picker :value="getEndPeriodValue" @update="updateTmpPeriod({ start: false, value: $event })">
        <div slot="title" class="title">Au</div>
      </date-picker>
    </div>
  </div>
</template>

<script>
  import { sendErrorToast } from '@/common/utils'
  import { DateTime } from 'luxon'
  import { mapGetters, mapMutations } from 'vuex'

  export default {
    name: 'Calendar',
    data() {
      return {
        showDatepickers: false,
        tmpStartPeriod: null,
        tmpEndPeriod: null
      }
    },
    computed: {
      ...mapGetters({ period: 'period/period' }),

      getStartPeriodValue() {
        return this.tmpStartPeriod ? this.tmpStartPeriod : this.period.start.toISODate()
      },

      getEndPeriodValue() {
        return this.tmpEndPeriod ? this.tmpEndPeriod : this.period.end.toISODate()
      }
    },
    methods: {
      ...mapMutations('period', ['SET_PERIOD']),

      toggleDatePicker() {
        if (this.showDatepickers) {
          const start = DateTime.fromISO(this.tmpStartPeriod ? this.tmpStartPeriod : this.period.start)
          const end = DateTime.fromISO(this.tmpEndPeriod ? this.tmpEndPeriod : this.period.end)
          if (start > end) return sendErrorToast(this.$t('errors.date'))
          if (!start.equals(this.period.start) || !end.equals(this.period.end)) this.SET_PERIOD({ start, end })
        }
        this.showDatepickers = !this.showDatepickers
      },

      updateTmpPeriod({ start, value }) {
        start ? this.tmpStartPeriod = value : this.tmpEndPeriod = value
      }
    }
  }
</script>

<style lang="scss" scoped>
  .calendar {
    position: relative;

    .btn-date {
      min-width: 5em;
      padding: 0.5em 1em;
      background-color: $app-white;
      border: 1px solid $app-grey-light;
      color: $text-color;
      border-radius: $radius-btn;
      transition: all 150ms ease-in-out;
      min-height: 2.2em;
      display: flex;
      justify-content: center;
      cursor: pointer;
      font-size: 0.9em;
      align-items: center;

      .dates {
        display: flex;
        flex-direction: column;
      }

      .chevron {
        margin-left: 1rem;
        transform: rotate(270deg);

        &.close { transform: rotate(90deg); }
      }

      mark {
        font-weight: 600;
      }
    }

    .datepickers {
      display: flex;
      box-shadow: 0 8px 6px 0 rgba(0, 0, 0, 0.05);
      user-select: none;
      box-sizing: content-box;
      background-color: white;
      z-index: 1002;
      border: 1px solid $app-grey-light;
      border-radius: 4px;
      position: absolute;
      right: 0;

      .date-picker { padding: 2rem; }
    }
  }
</style>
