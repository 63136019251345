<template>
  <div class="v-card-loading">
    <div class="v-card-loading-left">
      <div />
      <div />
      <div />
      <div />
    </div>

    <div class="v-card-loading-right" />
  </div>
</template>

<script>
  export default {
    name: 'LandingCard'
  }
</script>

<style lang="scss" scoped>

  .v-card-loading {
    display        : flex;
    flex-direction : row;
    border         : 1px solid $app-grey-light;
    border-radius  : 4px;
    height         : 100%;
    min-height     : 11em;
    background     : $app-white;
    width          : 100%;
    box-shadow     : 0 2px 5px 2px rgba(204, 204, 204, 0.25);
    cursor         : pointer;
    position       : relative;

    & > div {
      width    : 100%;
      height   : 100%;
      position : relative;
    }

    &-right {
      flex-shrink : 2;
      height      : 100%;
      background  : $app-grey-light;
      overflow    : hidden;

      &::after {
        content    : '';
        position   : absolute;
        height     : 100%;
        width      : 55%;
        animation  : loading 3s ease-in-out infinite;
        background : linear-gradient(
            to left,
            rgba(234, 234, 234, 0) 0%,
            lighten($app-grey, 35%) 37%,
            lighten($app-grey, 35%) 50%,
            lighten($app-grey, 35%) 63%,
            rgba(234, 234, 234, 0) 100%
        );
      }
    }

    &-left {
      position        : relative;
      display         : flex;
      flex-direction  : column;
      justify-content : space-between;
      padding         : 1em;
      & > div {
        position   : relative;
        width      : 100%;
        height     : 1em;
        background : $app-grey-light;
        overflow   : hidden;

        &::after {
          content    : '';
          position   : absolute;
          height     : 1em;
          width      : 55%;
          animation  : loading 3s ease-in-out infinite;
          background : linear-gradient(
              to left,
              rgba(234, 234, 234, 0) 0%,
              lighten($app-grey, 35%) 37%,
              lighten($app-grey, 35%) 50%,
              lighten($app-grey, 35%) 63%,
              rgba(234, 234, 234, 0) 100%
          );
        }
      }
    }

  }

  @keyframes loading {
    0% {
      transform : translateX(-100%);
    }
    50% {
      transform : translateX(250%);
    }
    100% {
      transform : translateX(-100%);
    }
  }


</style>
