<template>
  <div class="navbar">
    <div class="navbar-left">
      <div class="navbar-left-logo">
        <router-link :to="{ name: 'dashboard' }">
          <img class="logo" src="@/assets/illustrations/logo-tif-white.svg">
        </router-link>
      </div>
      <ul class="navbar-left-links">
        <router-link :class="{ active: isActiveRoute('dashboard') }" :to="{ name: 'dashboard' }" tag="div">
          <v-icon class="icon" font-size="1.8rem">home</v-icon>
          <p class="navbar-link-text">Dashboard</p>
        </router-link>
        <div class="cug-search">
          <v-icon class="search-icon" font-size="1.8rem">search</v-icon>
          <input :placeholder="$t('appNavbar.search')"
                 class="cug-input"
                 type="text"
                 @keyup.enter="$emit('cug-search', { cug: parseInt($event.target.value) })">
        </div>
      </ul>
    </div>
    <div class="navbar-right">
      <smart-notifications class="icon" :user="$user" />
      <!--      <a href="google.site" target='_blank'>-->
      <!--        <v-icon class="icon" font-size="1.3em">question</v-icon>-->
      <!--      </a>-->
      <a href="https://chat.google.com/room/AAAA5azng1A" target="_blank">
        <v-icon class="icon" font-size="1em">gchat</v-icon>
      </a>
      <more-apps />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import MoreApps from '@/components/base/MoreApps.vue'

  export default {
    name: 'Navbar',
    components: { MoreApps },
    computed: {
      ...mapGetters({
        notifications: 'notifications/notifications'
      }),

      nbNotification() {
        if (this.notifications) {
          return this.notifications.filter(el => !el.seen).length
        } else return 0
      }
    },
    methods: {
      isActiveRoute(route) {
        return this.$route.name === route
      }
    }
  }
</script>

<style lang="scss" scoped>
  .navbar {
    display: flex;
    height: 3.3em;
    width: 100vw;
    padding: 0 2.5em;
    background: $primary-color;
    box-shadow: $shadow-minimal;
    color: $secondary-color;

    &-left {
      flex: 1;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &-logo {
        height: 100%;
        padding: 1rem;
        margin-right: 1em;

        img {
          height: 100%;
        }
      }

      &-links {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;

        .router-link-active {
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          color: white;
          cursor: pointer;
          border-radius: 19px;
          height: 70%;
          padding: 0 1rem;
          font-size: 14px;

          &:hover { background-color: #853500; }

          &.active { background-color: #853500; }

          > .v-icon {
            color: white;
            margin-right: 0.8rem;
          }
        }

        .cug-search {
          height: 100%;
          width: 300px;
          background-color: white;
          margin-left: 5rem;
          display: flex;
          align-items: center;
          padding-left: 2rem;

          .search-icon { margin-right: 2rem; }

          .cug-input {
            border: 0;
            margin-top: 0.2rem;
            font-family: $primary-font;
            color: #5B5B62;
            font-size: 14px;
            width: 100%;
          }
        }
      }
    }

    &-right {
      display: flex;
      align-items: center;

      div { cursor: pointer; }

      .icon {
        color: white;
        margin-right: 2rem;
        cursor: pointer;
      }
    }
  }

  .container-app-auchan {
    max-width: 25%;
    position: fixed;
    right: 0;
    top: 3.3em;
    z-index: 2000;
    background: white;
    box-shadow: $shadow-medium;
    padding: 1.2em;
    display: flex;
    flex-wrap: wrap;

    a {
      padding: 1em;
      transition: 0.5s;
    }
  }
</style>
