<template>
  <div class="input-context">
    <div class="input-context-left" @click="$emit('clicked')">
      <span class="title">{{ $t(`context.${typeContext}.title`) }}</span>
      <span v-if="!context" class="value">{{ $t(`context.${typeContext}.placeholder`) }}</span>
      <span v-else class="value"> {{ labelOfContext }} </span>
    </div>
    <div class="search-context-right">
      <v-icon v-show="editable" size="2rem" @click="$emit('edit')">
        chevron-right
      </v-icon>
      <v-icon v-show="!editable" size="1.5rem" @click="$emit('delete')">
        x
      </v-icon>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'InputContext',
    props: {
      context: {
        type: Array,
        default: null
      },
      typeContext: {
        type: String,
        default: 'nomenclature'
      },
      level: {
        type: String,
        default: null
      }
    },
    computed: {
      editable() {
        return !this.context
      },

      labelOfContext(){
        return this.context.map(({label}) => label).join(', ')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .input-context {
    display         : flex;
    justify-content : space-between;
    align-items     : center;
    background      : white;
    border-bottom   : 1px solid $app-grey-light;
    height          : 6rem;
    padding         : 1em;

    &-left {
      display        : flex;
      flex-direction : column;
      width          : 80%;

      .title {
        flex      : 1;
        color     : $app-grey-dark;
        font-size : 1.2rem;
      }

      .value {
        text-overflow   : ellipsis;
        white-space     : nowrap;
        overflow        : hidden;
        color           : $secondary-color;
      }
    }

    &-right {
      display : flex;
    }

    &:hover {
      cursor : pointer;
    }
  }
</style>
