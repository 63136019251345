import { formatIndicators } from '@/common/utils'
import http from '@/http'
import Vue from 'vue'

const state = {
  indicators: {},
  product: {},
  productsReports: []
}

const getters = {
  getIndicatorsByFavorite: state => ({ favId }) => {
    const indicators = state.indicators[favId]
    if (!indicators) return
    return formatIndicators(indicators)
  },

  getProduct: state => {
    const product = state.product
    if (!product) return
    return formatIndicators(product)
  },

  getProductsReports: state => {
    const product = state.productsReports
    if (!product) return
    return product
  }
}

const mutations = {
  SET_FAVORITE_INDICATORS(state, { indicators, favId }) {
    Vue.set(state.indicators, favId, indicators)
  },

  SET_PRODUCT(state, product) {
    state.product = product
  },

  SET_PRODUCTS_REPORTS(state, product) {
    state.productsReports = product
  }
}

const actions = {
  async fetchFavoriteIndicators({ commit, rootGetters }, { favorite }) {
    const period = rootGetters['period/period']

    let searchParams = { startDate: period.start.toISODate(), endDate: period.end.toISODate() }

    if (favorite.nomenclature?.level === 'markets') searchParams.nomenclatureIn = 'market'
    if (favorite.nomenclature?.level === 'universes') searchParams.nomenclatureIn = 'univers'
    if (favorite.nomenclature?.level === 'families') searchParams.nomenclatureIn = 'family'
    if (!favorite.nomenclature?.level) searchParams.nomenclatureIn = 'allSector'

    searchParams.nomenclatureOut = 'allSector'
    if (favorite.productId) searchParams.productId = favorite.productId

    if (favorite.nomenclature?.items?.length) searchParams.nomenclature = favorite.nomenclature?.items?.map(nom => nom.sid)
    if (favorite.brand?.length) searchParams.brand = favorite.brand?.map(b => b.id)
    if (favorite.supplier?.length) searchParams.supplier = favorite.supplier?.map(s => s.id)
    if (favorite.cug) {
      searchParams.cug = favorite.cug
      searchParams.nomenclatureOut = 'product'
    }

    const token = await Vue.prototype.$user.getIdToken()

    await http
      .extend({ headers: { Authorization: `Bearer ${token}` } })
      .get('indicators/favorite', { searchParams })
      .json()
      .then(res => commit('SET_FAVORITE_INDICATORS', { favId: favorite.id, indicators: res }))
      .catch(err => console.error(err))
  },

  async fetchProduct({ commit, rootGetters }, cug) {
    const period = rootGetters['period/period']

    let searchParams = { startDate: period.start.toISODate(), endDate: period.end.toISODate() }

    searchParams.nomenclatureIn = 'allSector'
    searchParams.cug = cug
    searchParams.nomenclatureOut = 'product'

    const token = await Vue.prototype.$user.getIdToken()

    await http
      .extend({ headers: { Authorization: `Bearer ${token}` } })
      .get('indicators/favorite', { searchParams })
      .json()
      .then(res => commit('SET_PRODUCT', res))
      .catch(err => console.error(err))
  },

  async fetchProductsReports({ commit, rootGetters }, cug) {
    const period = rootGetters['period/period']

    let searchParams = { startDate: period.start.toISODate(), endDate: period.end.toISODate(), productId: cug }

    const token = await Vue.prototype.$user.getIdToken()

    await http
      .extend({ headers: { Authorization: `Bearer ${token}` } })
      .get('indicators/product', { searchParams })
      .json()
      .then(res => commit('SET_PRODUCTS_REPORTS', res))
      .catch(err => console.error(err))
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
