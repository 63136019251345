import Context from '@/views/Context'
import Dashboard from '@/views/Dashboard.vue'
import Details from '@/views/Details'
import Notification from '@/views/Notification'
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: Dashboard
    },
    {
      path: '/context/:id?',
      name: 'context',
      component: Context,
      props: route => ({ query: route.query.search, id: route.params.id && +route.params.id })
    },
    {
      path: '/details/:id',
      name: 'details',
      component: Details,
      props: true
    },
    {
      path: '/notifications',
      name: 'notifications',
      component: Notification
    }
  ]
})
