<template>
  <div class="dashboard">
    <div class="dashboard-header">
      <div class="dashboard-header-welcome">
        <p>
          Bienvenue sur votre dashboard
          <br>
          <span>{{ $user.firstName || '' | capitalize }}</span>
        </p>
      </div>
      <div class="dashboard-header-filter">
        <calendar />
      </div>
    </div>
    <div class="dashboard-body">
      <v-card
        v-for="(favorite, index) of favorites"
        :key="index"
        ref="cards"
        :edit-mode="editMode"
        :favorite="favorite"
        :index="index"
        :trash-mode="trashMode" />

      <div class="v-card-empty" @click="addCard">
        <v-icon size="2em">add-circle</v-icon>
        Créer un nouveau favori
      </div>
    </div>
    <div class="fab-buttons">
      <button class="button edit" @click="changeEditMode">
        <template v-if="!editMode">
          <v-icon key="edit" class="icon edit" font-size="2em">edit</v-icon>
          <span class="text">Editer un favori</span>
        </template>
        <v-icon v-else key="edit-cross" class="icon edit" font-size="2em">x</v-icon>
      </button>
      <button class="button delete" @click="changeTrashMode">
        <template v-if="!trashMode">
          <v-icon key="trash" class="icon delete" font-size="2em">trash</v-icon>
          <span class="text">Supprimer un favori</span>
        </template>
        <v-icon v-else key="trash-cross" class="icon delete" font-size="2em">x</v-icon>
      </button>
    </div>
  </div>
</template>

<script>
  import Card from '@/components/card/Index'
  import { mapGetters } from 'vuex'

  export default {
    name: 'Dashboard',
    components: { 'v-card': Card },
    data() {
      return {
        cards: null,
        trashMode: false,
        editMode: false
      }
    },
    computed: {
      ...mapGetters({ favorites: 'favorites/favorites' })
    },

    mounted() {
      this.cards = this.$refs.cards
    },

    methods: {
      addCard() {
        if (this.favorites.length >= 6)
          return this.$toasted.error(
            'Vous ne pouvez pas créer plus de 6 favoris, veuillez editer/supprimer un favoris existant'
          )
        this.$router.push({ name: 'context' })
      },
      changeTrashMode() {
        this.trashMode = !this.trashMode
        if (this.editMode) this.editMode = false
      },
      changeEditMode() {
        this.editMode = !this.editMode
        if (this.trashMode) this.trashMode = false
      }
    }
  }
</script>

<style lang="scss">
  .dashboard {
    padding: 2.5em;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-welcome {
        p {
          color: $secondary-color;

          span {
            font-weight: 600;
          }
        }
      }

      &-filter {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    &-body {
      display: grid;
      flex-wrap: wrap;
      justify-content: space-between;
      grid-template-columns: repeat(auto-fill, 32%);
      grid-gap: 2rem;
      margin-top: 1em;

      @include respond-to('max-width', 'large') {
        grid-template-columns: repeat(auto-fill, 100%);
      }

      & > * {
        align-items: stretch;
        cursor: pointer;
      }

      .v-card-empty {
        min-height: 11em;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: $app-grey-dark;
        border: 1px dashed $app-grey-dark;
        border-radius: 4px;
      }
    }

    .fab-buttons {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      position: absolute;
      right: 0;
      bottom: 0;

      .button {
        outline: 0;
        display: flex;
        width: 54px;
        height: 54px;
        background-color: $primary-color;
        border-radius: 50%;
        box-shadow: 0 2px 8px 0 $primary-color;
        margin: 0px 15px 15px 0px;
        border: none;
        justify-content: center;
        align-items: center;

        .icon {
          color: white;
        }

        .text {
          display: none;
        }

        .v-svg {
          margin: auto;
        }
      }

      .button:hover {
        width: 100%;
        padding: 0 1rem;
        border-radius: 32px;
        transition: width 0.5s, transform 0.5s;
        cursor: pointer;
        display: flex;
        align-items: center;

        .text {
          margin: auto;
          display: inline;
          color: white;
          justify-content: space-around;
          font-weight: 600;
          font-size: 17px;
          white-space: nowrap;
        }

        .icon {
          margin-left: 5px;
        }
      }
    }
  }
</style>
