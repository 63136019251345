<template>
  <div class="v-brand">
    <div class="back">
      <v-icon font-size="2rem" size="2em" @click="close"> chevron-left</v-icon>
    </div>
    <div class="v-brand-header">
      <div class="v-brand-header-left">
        <h3> Marques </h3>
        <mark v-if="nbBrandSelected > 0">{{ nbBrandSelected }}</mark>
        <span v-if="nbBrandSelected > 0" @click="resetBrand"> Réinitialiser</span>
      </div>
      <div class="v-brand-header-right">
        <search placeholder="Rechercher une marque" @search-change="searchBrand" />
      </div>
    </div>
    <div class="v-brand-chips">
      <span v-for="(brand,index) in brandsSelected" :key="index" class="chip">
        {{ brand.label }} <v-icon class="icon" @click="removeBrandSelected(brand.id)"> x </v-icon>
      </span>
    </div>
    <div class="v-brand-letters">
      <span v-for="(letter,index) in letters"
            :key="index"
            :class="{'active' : letter === letterSelected }"
            class="letter"
            @click="selectedLetter(letter)"> {{ letter }} </span>
    </div>
    <ul v-if="isReady" class="v-brand-list-item">
      <li v-for="(brand,index) in brands.result"
          :key="index"
          :class="{'selected' : isBrandSelected(brand.id)}"
          class="brand-item"
          @click="selectedBrand(brand)">
        <v-icon class="v-check" size="3em">check</v-icon>
        <span class="label"> {{ brand.label }} </span>
      </li>
    </ul>
    <div v-if="isReady" class="v-brand-footer">
      <ul class="paginate">
        <li v-show="nbPage !== 1 && page !== 1" @click="selectedPage(page - 1)">
          <v-icon> chevron-left</v-icon>
        </li>
        <li v-for="index in nbPage"
            v-if="pageIsVisible(index)"
            :key="index"
            :class="{'active' : index === page}"
            @click="selectedPage(index)">
          {{ index }}
        </li>
        <li v-else-if="nbPage > 20 && index === 21"> ...</li>
        <li v-show="nbPage > 1 && page !== nbPage" @click="selectedPage(page + 1)">
          <v-icon> chevron-right</v-icon>
        </li>
      </ul>
      <div class="count-page" @change="changeLimit($event)">
        <span>Résultat par page :
          <select>
            <option value="30">30</option>
            <option value="60">60</option>
            <option value="90">90</option>
            <option value="120">120</option>
          </select>
        </span>
      </div>
    </div>
    <transition name="slideup">
      <button v-if="nbBrandSelected > 0" class="btn fixed-bottom" @click="validate">Valider</button>
    </transition>
  </div>
</template>

<script>
  import { withLoader } from '@/common/decorators'
  import { generateAlphabet } from '@/common/utils'
  import Vue from 'vue'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    model: {
      prop: 'value',
      event: 'change'
    },

    props: {
      value: {
        type: Array,
        default: null
      },
      context: {
        type: Array,
        default: null
      }
    },
    data() {
      return {
        hasError: false,
        isReady: false,
        letters: [],
        letterSelected: 'a',
        page: 1,
        limit: 30,
        brandsSelected: [],
        search: ''
      }
    },

    computed: {
      ...mapGetters({
        brands: 'referential/brands'
      }),

      nbPage() {
        return Math.ceil(this.brands.totalCountResult / this.limit)
      },

      nbBrandSelected() {
        return this.brandsSelected.length
      }
    },

    async created() {

      this.brandsSelected = this.value || []
      this.letters = generateAlphabet()
      await this.fetchBrands()
    },

    methods: {

      ...mapActions({
        fetchBrands$: 'referential/fetchBrands'
      }),

      close() {
        this.$emit('close')
      },

      selectedLetter(letter) {
        this.search = ''
        this.page = 1
        this.letterSelected = letter
        this.fetchBrands()
      },

      selectedPage(page) {
        if (page > 0 && page <= this.nbPage) {
          this.page = page
          this.fetchBrands()
        }
      },

      selectedBrand(brand) {
        this.isBrandSelected(brand.id)
        ? this.removeBrandSelected(brand.id)
        : this.nbBrandSelected < 10 ? this.brandsSelected.push(brand) : Vue.toasted.error('Limitation à 10 marques', { duration: 5000 })
      },

      isBrandSelected(brandId) {
        return this.brandsSelected.some(el => el.id === brandId)
      },

      removeBrandSelected(brandId) {
        this.brandsSelected = this.brandsSelected.filter(el => el.id !== brandId)
      },

      resetBrand() {
        this.brandsSelected = []
      },

      searchBrand(value) {
        this.search = value
        this.page = 1
        this.letterSelected = value === '' ? 'a' : ''
        this.fetchBrands()
      },

      changeLimit($event) {
        this.limit = $event.target.value
        this.fetchBrands()
      },


      @withLoader
      async fetchBrands() {
        try {
          await this.fetchBrands$({
            letter: this.letterSelected,
            limit: this.limit,
            page: this.page,
            search: this.search
          })
          this.hasError = false
          this.isReady = true
        } catch (err) {
          this.hasError = true
        }
      },

      validate() {
        this.$emit('update-brand', this.brandsSelected)
        this.close()
      },

      pageIsVisible(index) {

        if (this.nbPage < 20) return true

        return (index === this.nbPage
          || index === 1
          || index === this.page
          || (index < this.page + 3 && index > this.page - 3))
      }
    }
  }

</script>

<style lang="scss" scoped>

  .v-brand {
    font-size: 1.6rem;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    width: 100%;
    user-select: none;
    padding: 3rem 5rem;
    position: relative;

    .back {
      position: absolute;
      left: 0.8em;
      cursor: pointer;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-left {
        display: flex;

        h3 {
          display: inline-block;
          margin: 0;
        }

        mark {
          display: inline-block;
          margin-left: 2rem;
          width: 23px;
          height: 23px;
          text-align: center;
          border-radius: 100%;
          background: $primary-color;
          color: $app-white;
        }

        span {
          margin-left: 1rem;
          color: $primary-color;
          cursor: pointer;
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      &-right {
        width: 30%;
      }
    }

    &-chips {
      display: flex;
      flex-wrap: wrap;

      .chip {
        margin-right: 1rem;
        background-color: $primary-color;
        box-shadow: $shadow-minimal;
        color: $app-white;
        font-size: 0.8em;
        border-radius: 500px;
        padding: 0.5em 1em;
        margin-top: 1em;
        white-space: nowrap;
        transition: all 150ms ease-in-out;

        & > .icon {
          cursor: pointer;
        }
      }
    }

    &-letters {
      display: flex;
      flex-wrap: wrap;
      margin-top: 2rem;

      .letter {
        cursor: pointer;
        border: 1px solid $app-grey;
        text-align: center;
        margin-right: 1rem;
        border-radius: 5px;
        padding: 0.5em;
        margin-top: 0.3em;
        width: 40px;
        height: 40px;
        text-transform: uppercase;

        &.active {
          background: $primary-color;
          border-color: $primary-color;
          color: $app-white;
        }
      }
    }

    &-list-item {
      display: grid;
      justify-content: space-between;
      grid-template-columns: repeat(auto-fill, 32%);
      margin-top: 2rem;
      box-shadow: 0 3px 1em rgba(#000, 0.1);
      font-size: 1.4rem;

      li {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        overflow: hidden;
        cursor: pointer;
        border-bottom: 1px solid #F5F5F5;
        background-color: #FFF;

        .v-check {
          position: relative;
          z-index: 0;
          cursor: pointer;
          color: $app-grey;
          background-color: #F5F5F5;
        }

        .label {
          position: relative;
          flex-grow: 1;
          padding-left: 1em;
          white-space: pre-wrap;
          align-self: stretch;
          display: flex;
          align-items: center;

          &:not(.check-all) {
            text-transform: uppercase;
          }
        }

        .icon {
          position: relative;
          flex-grow: 0;
          flex-shrink: 0;
        }

        &.leaf {
          .icon {
            color: transparent;
            pointer-events: none;
          }
        }

        &.something-selected:not(.selected) {
          background-color: transparent !important;

          .v-check {
            background-color: transparent;
          }
        }

        &.selected {
          box-shadow: 0 3px 6px rgba(#000, 0.05);
          color: $primary-color;
          background-color: #FFF;
          //box-shadow       : 0 3px 1em rgba(#000, 0.05);
          border-color: #FFF;

          .v-check {
            color: inherit;
            background-color: transparent;

            :global(& svg) {
              stroke-width: 3px;
            }
          }

          .label {
            box-shadow: none;
            border: none;
          }

          .icon {
            border: none;
          }
        }
      }
    }

    &-footer {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      margin-top: 1em;

      & > * {
        width: 33%;
      }

      .paginate {
        display: flex;
        justify-content: center;
        text-align: center;

        li {
          color: $secondary-color;
          cursor: pointer;
          text-align: center;
          padding: 0.2em;
          margin-top: 0.3em;
          margin-right: 0.3em;
          font-size: 0.9em;
          text-transform: uppercase;
          width: 25px;

          &.active {
            color: $primary-color;
          }
        }
      }

      .count-page {
        text-align: right;
        color: $secondary-color;
        font-size: 0.9em;

        select {
          color: $secondary-color;
          border: none;
          font-size: 1em;

          &:focus {
            border: none;
            outline: 0;
          }
        }
      }
    }
  }
</style>
