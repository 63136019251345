<template>
  <div class="product-reports">
    <template v-if="hasReports">
      <div class="header">
        {{ `${sortedReports.length} Rapports` }}
        <button
          v-for="(filter, i) in filters"
          :class="[{ primary: selectedFilter === i}, 'btn']"
          class="btn"
          @click="onFilterSelect(i)"
          v-html="$t(`details.productReports.headerBtn.${filter}`)" />
      </div>
      <div v-if="sortedReports.length" class="content">
        <div class="reports-list">
          <div class="reports">
            <div v-for="(report, i) in sortedReports"
                 :key="i"
                 :class="[{'active': i === currentReportIndex}, 'report']"
                 @click="currentReportIndex = i">
              <span class="id">{{ `N° ${report.interventionId}` }}</span>
              <span class="date">{{ report.interventionStartDate | dateFormatShort }}</span>
              <v-icon class="arrow-right" font-size="1.5rem">arrow-right</v-icon>
            </div>
          </div>
          <div v-if="sortedReports.length" class="download-all">
            <download-excel
              :data="sortedReports"
              :fields="json_fields"
              :name="getExcelSheetName"
              :worksheet="getExcelSheetName"
              default-value="N/A">
              <span>{{ $t('details.productReports.downloadAll') }}</span>
              <v-icon class="download" font-size="2rem">download</v-icon>
            </download-excel>
          </div>
        </div>
        <div v-if="sortedReports.length" class="report-details">
          <div class="title-id">
            <span class="id">{{ `N° ${currentReport.interventionId}` }}</span>
          </div>
          <div class="desc">
            <span class="desc-item">
              <mark>{{ $t('details.productReports.salesDate') }}</mark>{{ currentReport.salesDate | dateFormatShort }}
            </span>
            <span class="desc-item">
              <mark>{{ $t('details.productReports.interventionStartDate') }}</mark>{{ currentReport.interventionStartDate | dateFormatShort }}
            </span>
            <span class="desc-item">
              <mark>{{ $t('details.productReports.interventionEndDate') }}</mark>{{ currentReport.interventionEndDate | dateFormatShort }}
            </span>
            <span class="desc-item">
              <mark>{{ $t('details.productReports.breakdownTypeLabel') }}</mark>{{ currentReport.breakdownTypeLabel }}
            </span>
            <span class="desc-item">
              <mark>{{ $t('details.productReports.breakdownTypeCode') }}</mark>{{ currentReport.breakdownTypeCode }}
            </span>
            <span class="desc-item">
              <mark>{{ $t('details.productReports.repairStatusLabel') }}</mark>{{ currentReport.repairStatusLabel }}
            </span>
            <span class="desc-item">
              <mark>{{ $t('details.productReports.warrantyTypeId') }}</mark>{{ currentReport.warrantyTypeId }}
            </span>
            <span class="desc-item">
              <mark>{{ $t('details.productReports.breakdownCause') }}</mark>{{ currentReport.breakdownCause }}
            </span>
            <span class="desc-item">
              <mark>{{ $t('details.productReports.repairLabel') }}</mark>{{ currentReport.repairLabel }}
            </span>
          </div>
        </div>
      </div>
    </template>
    <div v-else class="noData">{{ $t('details.productReports.noReports') }}</div>
  </div>
</template>

<script>

  import { DateTime } from 'luxon'

  export default {
    name: 'ProductReports',
    props: {
      reports: {
        type: Array,
        default: () => ([])
      }
    },
    data() {
      return {
        currentReportIndex: 0,
        filters: ['IN_PROGRESS', 'DONE', 'ALL'],
        selectedFilter: 2,
        json_fields: {
          'CUG': 'productId',
          'Label': 'productLabel',
          'Intervention': 'interventionId',
          'Numero Serie': 'serialNumber',
          'Debut d\'intervention': 'interventionStartDate',
          'Fin d\'intervention': 'interventionEndDate',
          'Date de vente': 'salesDate',
          'Type de garantie': 'warrantyTypeId',
          'Statut Panne': 'breakdownTypeCode',
          'Description Panne': 'breakdownCause',
          'Statut reparation': 'repairStatusId',
          'Description Reparation': 'repairLabel',
          'Fournisseur': 'supplier'
        }
      }
    },
    computed: {
      sortedReports() {
        return this.reports
                   .filter(report => this.selectedFilter === 0 ? report.interventionEndDate === '2999-12-31' : this.selectedFilter === 1 ? report.interventionEndDate !== '2999-12-31' : true)
                   .sort((a, b) => DateTime.fromISO(a.interventionStartDate) - DateTime.fromISO(b.interventionStartDate))
      },

      currentReport() {
        return this.sortedReports[this.currentReportIndex]
      },

      hasReports() {
        return this.reports && this.reports.length > 0
      },

      getExcelSheetName() {
        return this.hasReports ? `SmartTif-${ this.reports[0].productId }-${ DateTime.local().toFormat('dd\'-\'LL\'-\'yyyy\'') }` : 'ExportSmartTIF'
      }
    },

    methods: {
      onFilterSelect(i) {
        this.selectedFilter = i
        this.currentReportIndex = 0
      }
    }
  }
</script>

<style lang="scss" scoped>
  .product-reports {
    display: flex;
    flex-direction: column;
    font-family: $primary-font;
    white-space: normal;

    .header {
      font-family: $primary-font--bold;
      font-size: 18px;
      font-weight: 600;
      padding: 0.3rem 0 0.5rem 1.5rem;
      display: flex;
      align-items: center;

      .btn {
        margin-left: 2rem;
        font-size: 14px;
      }
    }

    .content {
      display: flex;
      flex: 1;

      .reports-list {
        width: 20%;
        border-right: 1px solid $app-grey-light;
        margin-top: 1rem;
        max-height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .reports {
          max-height: 88%;
          overflow-y: auto;

          .report {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #929292;

            .arrow-right { margin-right: 1rem; }

            &.active {
              background-color: $primary-color;
              color: white;
            }

            &:not(:first-child) { border-top: 1px solid $app-grey-light; }

            &:not(.active) {
              &:hover {
                background-color: $app-grey-light;
                color: #929292;
              }
            }
          }
        }

        .report, .download-all {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-left: 1.5rem;
          cursor: pointer;
          min-height: 35px;
          font-size: 14px;
        }

        .download-all {
          color: $primary-color;

          &:hover {
            background-color: $primary-color;
            color: white;
          }

          .download {
            margin-right: 1rem;
            margin-left: 2rem;
          }
        }
      }

      .report-details {
        width: 80%;
        padding: 1rem 3rem;

        .title-id {
          display: flex;

          .date, .id {
            font-family: $primary-font--bold;
            font-size: 18px;
            font-weight: 700;
            margin-right: 0.2rem;
          }
        }

        .desc {
          margin-top: 1rem;

          &-item {
            margin-top: 1rem;
            font-size: 14px;
            display: block;
          }

          mark {
            font-weight: 600;
            margin: 0 0.5rem 0 0;
          }
        }
      }
    }
  }

  .noData { padding: 1rem }
</style>
