<template>
  <div class="date-picker">
    <slot name="title" />

    <div v-if="!showMonth && !showYear" class="year-month">
      <v-icon class="chevron left" h="1.5rem" @click="shiftMonthBy(-1)">left-chevron</v-icon>
      <span @click="showMonth = !showMonth">{{ monthsNames[currentMonth.month - 1] }} {{ currentMonth.year }}</span>
      <v-icon class="chevron right" h="1.5rem" @click="shiftMonthBy(1)">right-chevron</v-icon>
    </div>

    <div v-if="showMonth || showYear" class="year year-month">
      <span @click="onShowYear">{{ monthsNames[currentMonth.month - 1] }} {{ currentMonth.year }}</span>
    </div>

    <div v-if="!showMonth && !showYear">
      <div class="days-of-the-week">
        <div v-for="(day, index) of days" :key="`dayHeader#${index}`" class="cell header">{{ day }}</div>
      </div>
      <div class="cells">
        <div v-for="index in emptyCells" :key="`emptyCells#${index}`" class="cell empty" />
        <div v-for="day in daysInMonth"
             :key="`day#${day + emptyCells}`"
             :class="cssClasses(day)"
             class="cell value"
             @click="select(day)">
          {{ day }}
        </div>
      </div>
    </div>

    <div v-if="showMonth" class="cells">
      <div v-for="(month, i) in monthsNames"
           :key="`month#${month}`"
           class="cell value month"
           @click="selectMonth(i + 1)">
        {{ month }}
      </div>
    </div>

    <div v-if="showYear" class="cells">
      <div v-for="year in years"
           :key="`year#${year}`"
           class="cell value year"
           @click="selectYear(year)">
        {{ year }}
      </div>
    </div>
  </div>
</template>

<script>
  import { DateTime, Info } from 'luxon'

  const days = Info.weekdays('short').map(day => day.charAt(0))
  const monthsNames = Info.months('long')

  export default {
    name: 'DatePicker',
    props: {
      value: {
        type: String,
        default: null
      }
    },
    data() {
      return {
        showMonth: false,
        showYear: false,
        days,
        monthsNames,
        currentMonth: DateTime.fromISO(this.value || DateTime.local()).set({ day: 1 })
      }
    },
    computed: {
      value$() {
        return this.value ? DateTime.fromISO(this.value) : null
      },

      emptyCells() {
        return this.currentMonth.weekday - 1
      },

      daysInMonth() {
        return this.currentMonth.daysInMonth
      },

      years() {
        const nowYear = DateTime.local().year
        return Array(3).fill('').map((v, i) => nowYear - i)
      }
    },
    methods: {
      cssClasses(day) {
        return {
          selected: this.isSelected(day),
          today: this.currentMonth.set({ day }).toISODate() === DateTime.local().toISODate()
        }
      },
      shiftMonthBy(shift) {
        this.currentMonth = this.currentMonth.plus({ months: shift })
      },

      isSelected(day) {
        return this.currentMonth.set({ day }).toISODate() === (this.value$ && this.value$.toISODate())
      },

      select(day) {
        this.showCalendar = false
        this.formatError = false
        this.$emit('update', this.currentMonth.set({ day }).toISODate())
      },

      onShowYear() {
        this.showMonth = false
        this.showYear = true
      },

      selectMonth(month) {
        this.currentMonth = this.currentMonth.set({ month })
        this.showMonth = false
      },

      selectYear(year) {
        this.currentMonth = this.currentMonth.set({ year })
        this.showYear = false
        this.showMonth = true
      }
    }
  }
</script>

<style lang="scss" scoped>
  $s: 4rem;

  .date-picker {
    font-size: 1.2rem;
    overflow: hidden;
    color: grey;

    .title {
      font-size: 1.5rem;
      margin-left: 1.2rem;
    }

    .year-month {
      font-size: 1.2em;
      font-weight: bold;
      color: grey;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: capitalize;

      span {
        padding: 1em;
        cursor: pointer;

        &:hover {
          background-color: $primary-color;
          color: white;
        }
      }

      .chevron {
        padding: 1em;
        cursor: pointer;
        opacity: 0.5;

        &:hover {
          opacity: 1;
        }

        &.right {
          margin-left: 1em;
        }

        &.left {
          margin-right: 1em;
        }
      }
    }

    .cells {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      width: calc(23.5rem + 4em);
      margin-left: auto;
      margin-right: auto;

      &.year {
        width: 33rem;
      }
    }

    .days-of-the-week {
      color: grey;
      text-align: center;
      width: calc(24.5rem + 3em);
      margin: 1rem auto 0 auto;
    }

    .cell {
      position: relative;
      z-index: 1;
      font-size: 1em;
      width: $s;
      height: $s;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      box-sizing: border-box;

      &.value:hover {
        border: 2px solid lighten($primary-color, 20%);
        border-radius: 50%;
      }

      &.month, &.year {
        width: 50%;

        &:hover {
          border-radius: 0;
        }
      }

      &::before,
      &::after {
        z-index: -1;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: scale(0);
        transform-origin: center;
        transition: all .2s ease-out;
      }

      &.header {
        font-style: italic;
        cursor: default;
        text-transform: capitalize;
        color: inherit;
        font-size: 0.8em;
        margin: 0;
        height: 2em;
      }

      &.selected {
        color: #FFF;

        &::after {
          transform: scale(0.8);
          background-color: $primary-color;
          border-radius: 50%;
        }
      }

      &.day-disabled {
        color: #DDD;
        pointer-events: none;
      }

      &.empty {
        color: #CCC;
        cursor: default;
      }

      &.today {
        &::before {
          border-bottom: 2px solid $primary-color;
        }

        &:not(.highlighted1):not(.highlighted2)::before {
          transform: scaleX(0.2) scaleY(1);
        }
      }
    }
  }
</style>
