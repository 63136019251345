import bus    from '@/common/bus'
import router from '@/router'
import Vue    from 'vue'

export const withLoader = (target, key, descriptor) => {

  const fn         = descriptor.value
  descriptor.value = async function(...args) {
    bus.$showLoader()
    try {
      return await fn.apply(this, args)
    } catch (error) {
      console.error(error)
      Vue.toasted.error('Erreur de chargement')
    } finally {
      bus.$hideLoader()
    }
  }
}


export const retry = function(times = 3) {
  return function(target, key, descriptor) {
    const fn         = descriptor.value
    descriptor.value = async function(...args) {
      let tries     = 0
      const retryFn = async function() {
        try {
          return await fn.apply(this, args)
        } catch (error) {
          tries++
          if (tries <= times) {
            // eslint-disable-next-line no-console
            console.log('trying again', tries)
            const delay = ~~(Math.random() * 2000 + 5000)
            Vue.toasted.show('Chargement en cours ... Tentative n°' + tries + '/' + times)
            return await new Promise(resolve => setTimeout(() => resolve(retryFn()), delay))
          } else {
            tries = 0
            router.push({ name: 'error' })
            throw error
          }
        }
      }
      return await retryFn()
    }
  }
}
