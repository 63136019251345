<template>
  <div class="products-grid">
    <div v-for="header in columns" :key="`th-${header}`" :class="[header, 'th']" v-html="$t(`details.productsGrid.headers.${header}`)" />
    <div class="th reports" v-html="$t('details.productsGrid.headers.reports')" />

    <template v-for="(product, i) in sortedProducts">
      <div
        v-for="col in columns"
        :key="`tr-${col}-${i}`"
        :class="[col, { last: i === products.length - 1 }, 'tr']"
        v-html="formatGridValue(product[col], col)" />
      <div
        class="tr reports"
        @click="onShowReportsClicked(product, i)"
        v-html="showReportsProductId === i ? $t('details.productsGrid.rows.close') : $t('details.productsGrid.rows.reports')" />
      <product-reports v-if="showReportsProductId === i" :reports="reports" />
    </template>
  </div>
</template>

<script>
  import ProductReports from '@/components/details/ProductReports'
  import http from '@/http'
  import Vue from 'vue'
  import { mapActions, mapGetters } from 'vuex'

  const columns = [
    'labelNom',
    'groupNomenclature',
    'sales',
    'breakdown',
    'breakdownRate',
    'breakdownClosed',
    'breakdownInProgress',
    'intervention',
    'interventionRate',
    'interventionClosed',
    'interventionInProgress',
    'supplier'
  ]

  export default {
    name: 'ProductsGrid',
    components: { ProductReports },
    props: {
      products: {
        type: Array,
        default: () => {}
      }
    },
    data() {
      return {
        columns,
        showReportsProductId: undefined,
        reports: null
      }
    },

    computed: {
      ...mapGetters({ period: 'period/period', productsReports: 'indicators/getProductsReports' }),

      sortedProducts() {
        return this.products
          ? this.products.sort((a, b) => {
              if (a.breakdownRate > 0 && b.breakdownRate <= 0) return -1
              if (a.breakdownRate <= 0 && b.breakdownRate > 0) return 1
              if (a.breakdownRate <= 0 && b.breakdownRate <= 0) return 0
              return b.breakdownRate - a.breakdownRate
            })
          : []
      }
    },

    methods: {
      ...mapActions({ fetchProductsReports: 'indicators/fetchProductsReports' }),
      formatGridValue(val, col = undefined) {
        const isRate = col === 'breakdownRate' || col === 'interventionRate'
        if (val === null) return 'N/A'
        if (isFinite(val) && (val % 1 !== 0 || isRate)) return `${val.toFixed(2)}%`
        return val
      },

      async onShowReportsClicked(product, i) {
        if (i === this.showReportsProductId) return (this.showReportsProductId = undefined)
        this.$showLoader()
        await this.fetchReportsByProduct(product)
        this.reports = this.productsReports
        this.showReportsProductId = i
        this.$hideLoader()
      },

      async fetchReportsByProduct(cug) {
        await this.fetchProductsReports(cug.groupNomenclature)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .products-grid {
    margin-top: 2rem;
    display: grid;
    border-radius: 16px;
    background-color: #ffffff;
    grid-template-columns: 3fr repeat(12, auto);
    border-left: 1px solid $app-grey-light;
    border-bottom: 1px solid $app-grey-light;
    border-right: 1px solid $app-grey-light;
    white-space: nowrap;
    overflow: auto;

    .th,
    .tr {
      padding: 1.5rem;
      border-top: 1px solid $app-grey-light;
      text-align: right;
    }

    .th,
    .labelNom {
      font-family: $primary-font--bold;
      font-size: 14px;
      font-weight: 600;
    }

    .tr {
      font-family: $primary-font;
      font-size: 13px;

      &.reports {
        color: $primary-color;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .labelNom {
      text-align: left;
    }

    .product-reports {
      grid-column-start: span 13;
    }

    .breakdown,
    .intervention {
      border-left: 1px solid #e8e8e8;
    }
  }

  @media screen and (min-width: 200px) and (max-width: 1600px) {
    .products-grid {
      max-height: 500px;
    }
  }
</style>
