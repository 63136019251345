<template>
  <div class="context">
    <div v-if="view === null" class="context-wrapper">
      <div class="context-illustration">
        <img src="@/assets/illustrations/onboarding.svg">
      </div>
      <div class="context-form">
        <div class="context-form-header">
          <h3>{{ $t(`context.template.${mode}.title`) }}</h3>
          <p>{{ $t(`context.template.${mode}.intro`) }}</p>
        </div>
        <div class="context-form-body">
          <div class="input-wrapper">
            <input v-model="nameFavorite" placeholder="Ajouter ici un nom pour votre favori (facultatif)" type="text">
          </div>
          <input-context
            :context="itemsNomenclature"
            type-context="nomenclature"
            @clicked="editContext('nomenclature')"
            @delete="deleteContext('nomenclature')" />
          <input-context
            :context="brand"
            type-context="brand"
            @clicked="editContext('brand')"
            @delete="deleteContext('brand')" />
          <input-context
            :context="supplier"
            type-context="supplier"
            @clicked="editContext('supplier')"
            @delete="deleteContext('supplier')" />
        </div>

        <div class="context-form-footer">
          <button class="btn primary around" @click.once="validateContext">
            {{ $t(`context.template.${mode}.button`) }}
          </button>
          <button v-if="mode === 'edition'" class="btn around" @click="showPopupRemoveFavorite = true">
            Supprimer
          </button>
        </div>
      </div>
    </div>
    <v-nomenclature
      v-if="view === 'nomenclature'"
      v-model="nomenclature"
      @close="view = null"
      @update-nomenclature="val => (nomenclature = val)" />
    <v-brand v-if="view === 'brand'" v-model="brand" @close="view = null" @update-brand="val => (brand = val)" />
    <v-supplier
      v-if="view === 'supplier'"
      v-model="supplier"
      @close="view = null"
      @update-supplier="val => (supplier = val)" />

    <popup :is-visible.sync="showPopupRemoveFavorite" @popup-close="showPopupRemoveFavorite = false">
      <div class="popup">
        <div class="popup-header">
          <h3>Suppresion du favori</h3>
          <v-icon class="icon" @click="showPopupRemoveFavorite = false">x</v-icon>
        </div>
        <div class="popup-body">
          Êtes-vous sur de vouloir supprimer ce favori ?
        </div>
        <div class="popup-footer">
          <button class="btn" @click="showPopupRemoveFavorite = false">Non</button>
          <button class="btn primary" @click.once="deleteFavorite">Oui</button>
        </div>
      </div>
    </popup>
  </div>
</template>

<script>
  import Brand from '@/components/context/Brand'
  import InputContext from '@/components/context/InputContext'
  import Nomenclature from '@/components/context/Nomenclature'
  import Supplier from '@/components/context/Supplier'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'Context',
    components: {
      'input-context': InputContext,
      'v-nomenclature': Nomenclature,
      'v-brand': Brand,
      'v-supplier': Supplier
    },

    props: {
      id: {
        type: Number,
        default: null
      }
    },

    data() {
      return {
        view: null,
        nameFavorite: null,
        nomenclature: null,
        brand: null,
        supplier: null,
        favId: null,
        showPopupRemoveFavorite: false
      }
    },

    computed: {
      ...mapGetters({ favoriteByIndex: 'favorites/favoriteByIndex' }),

      itemsNomenclature() {
        return this.nomenclature && this.nomenclature.items ? this.nomenclature.items : null
      },

      mode() {
        return this.id !== null ? 'edition' : this.$route.query.search ? 'search' : 'creation'
      }
    },

    async mounted() {
      if (this.id !== null) {
        const favorite = this.favoriteByIndex(this.id)
        if (favorite) {
          this.nameFavorite = favorite.name
          this.nomenclature = favorite.nomenclature
          this.brand = favorite.brand
          this.supplier = favorite.supplier
          this.favId = favorite.id
        } else {
          this.$router.push({ name: 'context' })
        }
      }
    },

    methods: {
      ...mapActions({
        addFavorite: 'favorites/addFavorite',
        editFavorite: 'favorites/editFavorite',
        removeFavorite: 'favorites/removeFavorite'
      }),

      deleteContext(typeOfContext) {
        this[typeOfContext] = null
      },

      editContext(typeOfContext) {
        this.context = this[typeOfContext]
        this.view = typeOfContext
      },

      validateContext() {
        const favorite = {
          name: this.nameFavorite,
          brand: this.brand,
          supplier: this.supplier,
          nomenclature: this.nomenclature,
          id: this.favId
        }

        switch (this.mode) {
          case 'creation':
            this.addFavorite(favorite)
            this.$router.push({ name: 'dashboard' })
            break
          case 'edition':
            this.editFavorite({ favorite })
            this.$router.push({ name: 'dashboard' })
            break
        }
      },

      async deleteFavorite() {
        await this.removeFavorite({ id: this.id })
        this.$router.push({ name: 'dashboard' })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .context {
    margin: 2.5em;
    background: $app-white;
    border: 1px solid $app-grey-light;
    border-radius: 5px;

    &-wrapper {
      display: flex;
      justify-content: space-between;
      padding: 10rem 3rem;
    }

    &-illustration {
      width: 50%;
      text-align: center;

      img {
        width: 40rem;
      }

      @include respond-to('max-width', 'large') {
        display: none;
      }
    }

    &-form {
      flex: 1;
      width: 50%;

      &-body {
        width: 100%;

        .input-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: white;
          border-bottom: 1px solid $app-grey-light;
          margin-bottom: 1rem;

          input {
            flex: 1;
            border: none;
            height: 100%;
            padding: 1em;
            font-size: 1em;
          }

          ::placeholder {
            font-family: $primary-font;
            font-size: 1em;
          }
        }
      }

      &-footer {
        margin: 1em 0 0.5em;
        display: flex;
        justify-content: center;

        button {
          min-width: 10em;

          &:not(.primary) {
            margin-left: 2em;
          }
        }
      }
    }
  }

  .popup {
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .icon {
        cursor: pointer;
      }
    }

    &-body {
      margin: 1em 0 2em;
    }

    &-footer {
      display: flex;
      justify-content: center;
      margin: 1em 0 0.5em;

      .btn {
        margin-right: 1em;
      }
    }
  }
</style>
