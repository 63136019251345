<template>
  <i class="v-icon"
     :style="style"
     v-bind="$attrs"
     v-on="$listeners"
     v-html="icon" />
</template>

<script>
  export default {
    name: 'VIcon',
    props: {
      size: {
        default: '1em'
      },
      fontSize: {
        default: '1em'
      }
    },
    computed: {
      icon() {
        const name = this.$slots.default[0].text.trim()
        return require(`!svg-inline-loader!@/assets/icons/${name}.svg`)
      },

      style() {
        return {
          width: this.size,
          height: this.size,
          fontSize: this.fontSize
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .v-icon {
    display         : inline-flex;
    justify-content : center;
    align-items     : center;
    vertical-align  : middle;

    ::v-deep svg {
      width  : 1em;
      height : 1em;
      stroke : currentColor;
    }
  }
</style>
