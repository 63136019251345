<template>
  <div class="breadcrumb">
    <transition-group
      name="bread"
      class="breadcrumb-container">
      <span
        v-for="(item,index) in breadcrumb"
        :key="`${index}_${item}`"
        :class="{'link-item' : true, 'disabled' : somethingSelected > 0}"
        @click="navBreadcrumb(item)">
        <v-icon size="1.3rem" class="chevron">chevron-right</v-icon>
        {{ item.label }}
      </span>
    </transition-group>
  </div>
</template>

<script>
  export default {
    name: 'Breadcrumb',
    props: {
      breadcrumb: {
        type: Array,
        default: null
      },
      somethingSelected: {
        type: Number,
        default: 0
      }
    },
    methods: {
      navBreadcrumb(breadcrumb, index) {
        this.$emit('nav-breadcrumb', breadcrumb, index)
      }
    }
  }
</script>

<style lang="scss">
  .breadcrumb {
    display         : flex;

    &-container {

      .link-item {
        &:first-child {
          .chevron {
            display : none;
          }
        }
        &:hover {
          cursor : pointer;
        }

        &.disabled {
          background : transparent!important;
        }
      }
    }
  }


</style>
