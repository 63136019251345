<template>
  <div :class="[{ selected, 'alert': isCardWarning }, 'nom-card']">
    <div v-if="isCardWarning" class="alert-icon">!</div>
    <div class="title">{{ indicators.labelNom }}</div>
    <div class="indicators">
      <div class="indicator sales">
        {{ $t('KPIS.sales') }}
        <span class="value">{{ indicators.sales | formatValue }}</span>
      </div>
      <div class="indicator breakdown">
        {{ $t('KPIS.breakdown') }}
        <span class="value">{{ indicators.breakdown | formatValue }}</span>
      </div>
      <div class="indicator breakdown-rate">
        {{ $t('KPIS.breakdownRate') }}
        <span class="value">{{ indicators.breakdownRate | formatDecimals(2) }}</span>
      </div>
      <div class="indicator breakdown-in-progress">
        {{ $t('KPIS.breakdownInProgress') }}
        <span class="value">{{ indicators.breakdownInProgress | formatValue }}</span>
      </div>
      <div class="indicator breakdown-closed">
        {{ $t('KPIS.breakdownClosed') }}
        <span class="value">{{ indicators.breakdownClosed | formatValue }}</span>
      </div>
      <div class="indicator intervention">
        {{ $t('KPIS.intervention') }}
        <span class="value">{{ indicators.intervention | formatValue }}</span>
      </div>
      <div class="indicator intervention-rate">
        {{ $t('KPIS.interventionRate') }}
        <span class="value">{{ indicators.interventionRate | formatDecimals(2) }}</span>
      </div>
      <div class="indicator intervention-in-progress">
        {{ $t('KPIS.interventionInProgress') }}
        <span class="value">{{ indicators.interventionInProgress | formatValue }}</span>
      </div>
      <div class="indicator intervention-closed">
        {{ $t('KPIS.interventionClosed') }}
        <span class="value">{{ indicators.interventionClosed | formatValue }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'NomCard',
    props: {
      indicators: {
        type: Object,
        default: () => ({})
      },
      selected: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      isCardWarning() {
        return this.indicators.interventionRate > 5 || this.indicators.breakdownRate > 5
      }
    }
  }
</script>

<style lang="scss" scoped>
  .nom-card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border: 1px solid $app-grey-light;
    border-radius: 16px;
    background-color: #FFF;
    padding: 2rem;
    font-family: $primary-font;
    cursor: pointer;
    min-width: fit-content;
    position: relative;

    .alert-icon {
      position: absolute;
      right: -15px;
      top: -23px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $app-red;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 2px solid $app-white;
      font-weight: 600;
      font-size: 1.5em;
      color: white;
    }

    &:hover { background-color: #FFF8F5; }

    &.selected {
      background-color: $primary-color;
      border: 1px solid #853500;
      color: white;
    }

    .title {
      font-weight: 600;
      font-size: 18px
    }

    .indicators {
      line-height: 2rem;

      .breakdown, .intervention {
        margin-top: 0.5rem;
      }

      .indicator {
        font-family: $primary-font;
        font-size: 13px;

        .value {
          font-weight: 700;
          font-size: 14px;
        }
      }
    }
  }

</style>
