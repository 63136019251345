import { v4 as uuidv4 } from 'uuid'
import firebase from 'firebase/app'
import 'firebase/firestore'
import Vue from 'vue'

const db = firebase.firestore()
const favoritesCollection = db.collection('FAVORITES')

const state = {
  favorites: [],
  currentFavorite: null
}

const mutations = {
  ADD_FAVORITE(state, favorite) {
    state.favorites.push(favorite)
  },
  EDIT_FAVORITE(state, { favorite }) {
    state.favorites = state.favorites.map(fav => {
      if (fav.id.toString() === favorite.id.toString()) {
        fav = favorite
      }
      return fav
    })
  },
  REMOVE_FAVORITE(state, { id }) {
    state.favorites.splice(id, 1)
  },
  SET_FAVORITES(state, favorites) {
    state.favorites = favorites
  },
  SET_CURRENT_FAVORITE(state, favorite) {
    state.currentFavorite = favorite
  }
}

const actions = {
  async addFavorite({ commit, dispatch }, favorite) {
    if (favorite !== null) {
      favorite.id = uuidv4()
      commit('ADD_FAVORITE', favorite)
      dispatch('sendFavorites')
    }
  },

  editFavorite({ commit, dispatch }, favorite) {
    if (favorite !== null) {
      commit('EDIT_FAVORITE', favorite)
      dispatch('sendFavorites')
    }
  },

  removeFavorite({ commit, dispatch }, id) {
    commit('REMOVE_FAVORITE', id)
    dispatch('sendFavorites')
  },

  async fetchFavorites({ commit }) {
    const favoritesDoc = await favoritesCollection.doc(Vue.prototype.$user.email).get()
    if (!favoritesDoc.exists) {
      commit('SET_FAVORITES', [])
    } else {
      commit('SET_FAVORITES', Array.isArray(favoritesDoc?.data()?.favorites) ? favoritesDoc?.data()?.favorites : [])
    }
  },

  async sendFavorites({ state }) {
    await favoritesCollection.doc(Vue.prototype.$user.email).set({
      favorites: state.favorites
    }, { merge: true })
  }
}

const getters = {
  favorites: state => state.favorites || [],
  favoriteByIndex: state => id => state.favorites[id] || null,
  currentFavorite: state => state.currentFavorite || null
}

export default {
  state,
  mutations,
  actions,
  getters
}
