<template>
  <div id="app">
    <v-loader v-show="isLoaderVisible" />
    <app-navbar @cug-search="onCugSearch" />
    <router-view v-if="isReady" />
    <cug-search-popup v-if="showCugPopup" :product="productSearched" @close="showCugPopup = false" />
  </div>
</template>

<script>
  import AppNavbar from '@/components/dashboard/AppNavbar'
  import { formatIndicators } from '@/common/utils'
  import CugSearchPopup from '@/components/dashboard/CugSearchPopup'
  import { mapActions, mapGetters } from 'vuex'
  import http from '@/http'

  export default {
    name: 'App',
    components: { CugSearchPopup, AppNavbar },
    data() {
      return {
        hasError: false,
        isReady: false,
        isLoading: 0,
        productSearched: null,
        showCugPopup: false
      }
    },
    computed: {
      ...mapGetters({ period: 'period/period', product: 'indicators/getProduct', productsReports: 'indicators/getProductsReports' }),

      isLoaderVisible() {
        return this.isLoading
      }
    },

    async created() {
      this.$bus.$on('show-loader', () => this.isLoading++)
      this.$bus.$on('hide-loader', () => (this.isLoading = this.isLoading ? this.isLoading - 1 : 0))
      await this.fetch()
    },

    methods: {
      ...mapActions({
        fetchReferential: 'referential/fetchReferential',
        fetchFavorites: 'favorites/fetchFavorites',
        fetchNotifications: 'notifications/fetch',
        fetchFavoriteIndicators: 'indicators/fetchFavoriteIndicators',
        fetchProduct: 'indicators/fetchProduct',
        fetchProductsReport: 'indicators/fetchProductsReports'
      }),

      async fetch() {
        try {
          // await Promise.all([this.fetchFavorites(), this.fetchReferential(), this.fetchNotifications()])
          await Promise.all([this.fetchFavorites(), this.fetchReferential()])
        } catch (err) {
          this.hasError = true
        } finally {
          this.isReady = true
        }
      },

      async onCugSearch({ cug }) {
        this.showCugPopup = false
        this.$showLoader()
        await this.fetchProduct(cug)
        await this.fetchProductsReport(cug)

        this.productSearched = this.product
        this.productSearched.reports = this.productsReports

        this.$hideLoader()
        this.showCugPopup = true
      }
    }
  }
</script>

<style lang="scss">
  #app {
    font-family: 'Poppins', sans-serif;
    background: $app-background;
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
</style>
