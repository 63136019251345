<template>
  <div class="chip-container">
    <label class="chip">
      <v-icon font-size="1.3em"> {{ icon }}</v-icon>
      <span> {{ label }} </span>
      <span v-if="value" :class="[{ alert: rate && rate >= 5 }, 'bold']">{{ value | formatValue }}</span>
      <span v-if="rate && value" :class="[{ alert: rate && rate >= 5 }]">/</span>
      <span v-if="rate" :class="[{ alert: rate && rate >= 5 }, 'bold']">{{ rate | formatDecimals(2) }}</span>
    </label>
    <label v-if="list && list.length > 1" v-tooltip="listOfItems" class="chip more"> {{ `+${list.length-1}` }}</label>
  </div>
</template>

<script>

  export default {
    name: 'Chips',

    props: {
      label: {
        type: String,
        default: null
      },
      icon: {
        type: String,
        default: null
      },
      list: {
        type: Array,
        default: () => []
      },
      rate: {
        type: Number,
        default: null
      },
      value: {
        type: Number,
        default: null
      }
    },

    computed: {
      listOfItems() {
        const list = this.list.slice(1)
        return list.map(({ label }) => label).join(', ')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .chip-container {
    display: flex;
    margin-right: 1em;
  }

  .chip {
    background: $app-white;
    padding: 1rem 1.5rem;
    display: flex;
    align-items: center;
    border-radius: 500px;
    border: 1px solid $app-grey-light;
    z-index: 2;
    font-size: 12px;

    .bold {
      font-weight: bold;
      font-size: 14px;
    }

    .alert { color: #ED8B88 }

    span {
      margin-left: 0.5em;
    }

    &.more {
      cursor: pointer;
      background: $app-grey-light;
      margin-left: -4rem;
      z-index: 1;
      width: 7.5rem;
      justify-content: flex-end;
    }
  }
</style>
