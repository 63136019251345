<template>
  <div class="more-apps">
    <button @click="showMoreApps = !showMoreApps">
      <v-icon class="selector-icon">menu-dots</v-icon>
    </button>
    <transition name="pop">
      <div v-if="showMoreApps" class="more-apps-popin">
        <div v-for="(category, i) of apps" :key="i" class="app-category">
          <div class="title">
            {{ category.label }}
          </div>
          <div class="app-list">
            <a v-for="app of category.applications"
               :key="app.name"
               class="application"
               :href="app.link"
               target="_blank">
              <div class="img-container">
                <img :src="`/img/apps-logos/${app.logo}`">
              </div>
              <div class="label">
                {{ app.name }}
              </div>
            </a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    name: 'MoreApps',
    data() {
      return {
        showMoreApps: false,
        apps: [
          {
            label: '📈 Pilotage',
            applications: [
              { name: `Bento`, logo: 'bento.png', link: 'https://bento.dataauchan.fr' },
              { name: `Pilot`, logo: 'pilot.svg', link: 'https://pilot.auchan.fr' },
              { name: `Pilot'Stock`, logo: 'pilotstock.png', link: 'https://stocks.data.fr.auchan.com/#/' },
              { name: `Pilot'Promo`, logo: 'pilotpromo.png', link: 'http://promo.fr.auchan.com/home' }
            ]
          },
          {
            label: '🦊 Smart Suite',
            applications: [
              { name: `Smart Live`, logo: 'smartlive.png', link: 'https://live.pilot.auchan.fr/' },
              { name: `Smart Business`, logo: 'smartbusiness.png', link: 'https://smartbusiness.data.fr.auchan.com/' },
            ]
          },
          {
            label: '🕵️ Data Suite',
            applications: [
              { name: `DataStore`, logo: 'datastore.svg', link: 'https://datastore.auchan.fr' },
              { name: `DataPerf`, logo: 'dataperf.svg', link: 'https://dataperf.fr.auchan.com' },
              { name: `DataCamp`, logo: 'datacamp.svg', link: 'https://datacamp.fr.auchan.com' }
            ]
          }
        ]
      }
    },

    watch: {
      showMoreApps() {
        if (this.showMoreApps) window.addEventListener('click', this.clickOutside)
        else window.removeEventListener('click', this.clickOutside)
      }
    },
    methods: {
      clickOutside(e) {
        this.showMoreApps = !!this.$el.contains(e.target)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .more-apps {
    position: relative;
    color: inherit;
    .selector-icon {
        color: white;
        cursor: pointer;
    }
    button {
      color: inherit;
      background: none;
      border: none;
      outline: none;
    }
    .more-apps-popin {
      position: absolute;
      top: 170%;
      right: 0rem;
      background-color: white;
      border-radius: 0.25em;
      padding-bottom: 0.875em;
      z-index: 100;
      border: 1px solid #cacbcc;

      .app-category {
        padding: 1em 1em 0 1em;

        .title {
          background-color: #E5E5E5;
          border-radius: 0.1875em 0.1875em 0 0;
          padding: 0.3125em 0.3125em 0.3125em 0.625em;
          color: black;
        }

        .app-list {
          display: flex;

          .application {
            margin: 0.375em 0.75em;
            min-width: 3.75em;
            transition: all 0.1s ease-out;

            .img-container {
              padding: 0.3125em;
              height: 3.125em;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                height: 100%;
                border-radius: 0.25em;
                transition: all 0.4s ease-out;
              }
            }

            .label {
              padding-top: 0.3125em;
              line-height: 0.625em;
              text-align: center;
              cursor: pointer;
              width: 100%;
              color: black;
            }

            &:hover {
              transform: scale(1.2);
            }
          }
        }
      }
    }
  }
  .pop-enter-active {
    animation: popup-scale-y 310ms;
    transform-origin: top right;
  }

  .pop-leave-active {
    animation: popup-scale-y 310ms reverse;
    transform-origin: top right;
  }

  .pop-enter,
  .pop-leave-to {
    transform: scale(0);
  }

  .pop-enter-to,
  .pop-leave {
    transform: scale(1);
  }

  @keyframes popup-scale-y {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    50% {
      transform: scale(1.1);
      opacity: 1;
    }
    100% {
      transform: scale(1);
    }
  }
</style>
