<template>
  <div class="details">
    <div class="header">
      <div class="go-back">
        <v-icon class="chevron-left" font-size="2rem" @click="$router.push({ name: 'dashboard'})">chevron-left</v-icon>
        <label class="title">{{ $t('details.title') }}</label>
      </div>
      <div class="filters">
        <details-breadcrumb :favorite="currentFavorite" :indicators="currentFavoriteIndicators" />
        <calendar />
      </div>
    </div>
    <div class="separator" />
    <div v-for="(childrens, nomLevel, nomIndex) in tree" :key="nomIndex" :class="[nomLevel, 'level']">
      <v-icon v-if="nomIndex > 0" class="arrow-down" font-size="6rem">arrow-down</v-icon>
      <template v-if="nomLevel !== 'product'">
        <div class="nom-label">
          {{ $t(`details.levels.${nomLevel}`) }}
          <download-excel
            :data="indicators[nomLevel]"
            :fields="json_fields_cards"
            :name="`SmartTif-${ $t(`details.levels.${nomLevel}`) }-${ getCurrentDate }`"
            :worksheet="`SmartTif-${ $t(`details.levels.${nomLevel}`) }-${ getCurrentDate }`"
            class="download-btn"
            default-value="N/A">
            <span>{{ $t(`details.download.nom.${nomLevel}`) }}</span>
            <v-icon class="download" font-size="1.5rem">download</v-icon>
          </download-excel>
          <download-excel
            v-if="nomLevel === 'family' || nomLevel === 'market'"
            :before-generate="setTmpNomProductsLevel(nomLevel)"
            :fetch="fetchExcelNomProductsExport"
            :fields="json_fields_products"
            :name="`SmartTif-Produits-${ $t(`details.levels.${ nomLevel }`) }-${ getCurrentDate }`"
            :worksheet="`SmartTif-Produits-${ $t(`details.levels.${ nomLevel }`) }-${ getCurrentDate }`"
            class="download-btn"
            default-value="N/A">
            <span>{{ $t(`details.download.products.${ nomLevel }`) }}</span>
            <v-icon class="download" font-size="1.5rem">download</v-icon>
          </download-excel>
        </div>
        <div class="cards">
          <nom-card v-for="(child, childIndex) in childrens"
                    :key="childIndex"
                    :indicators="child"
                    :selected="child.groupNomenclatureSid === selectedNoms[nomLevel]"
                    @click.native="onNomCardClicked({ level: nomLevel, index: childIndex, sid: child.groupNomenclatureSid})" />
        </div>
      </template>
      <template v-else>
        <div class="nom-label">
          {{ $t(`details.levels.product`) }}
          <download-excel
            :data="indicators.product"
            :fields="json_fields_products"
            :name="`SmartTif-Produits-${ getCurrentDate }`"
            :worksheet="`SmartTif-Produits-${ getCurrentDate }`"
            class="download-btn"
            default-value="N/A">
            <span>{{ $t('details.download.nom.product') }}</span>
            <v-icon class="download" font-size="1.5rem">download</v-icon>
          </download-excel>
        </div>
        <products-grid :products="childrens" />
      </template>
    </div>
  </div>
</template>

<script>
  import { formatChildrensIndicatorsList, getNextNomLevel, getParentNomLevel } from '@/common/utils'
  import DetailsBreadcrumb from '@/components/details/DetailsBreadcrumb'
  import NomCard from '@/components/details/NomCard'
  import ProductsGrid from '@/components/details/ProductsGrid'
  import http from '@/http'
  import { DateTime } from 'luxon'
  import Vue from 'vue'
  import { mapActions, mapGetters, mapMutations } from 'vuex'

  const initIndicators = { univers: [], market: [], family: [], product: [] }
  const initSelectedNoms = { univers: null, market: null, family: null, product: null }

  export default {
    name: 'Details',
    components: { ProductsGrid, NomCard, DetailsBreadcrumb },
    props: {
      id: {
        type: [Number, String],
        required: true
      }
    },
    data() {
      return {
        indicators: initIndicators,
        selectedNoms: initSelectedNoms,
        tmpNomProductsLevel: undefined,
        json_fields_cards: {
          'Label': 'labelNom',
          'Ventes': 'sales',
          'Nb pannes': 'breakdown',
          'Taux de pannes': {
            field: 'breakdownRate',
            callback: value => parseFloat(value).toFixed(2)
          },
          'Pannes clotûrées': 'breakdownClosed',
          'Pannes en cours': 'breakdownInProgress',
          'Interventions': 'intervention',
          'Taux d\'interventions': {
            field: 'interventionRate',
            callback: value => parseFloat(value).toFixed(2)
          },
          'Interventions clotûrées': 'interventionClosed',
          'Intervention en cours': 'interventionInProgress'
        },
        json_fields_products: {
          'Label': 'labelNom',
          'CUG': 'groupNomenclature',
          'Ventes': 'sales',
          'Pannes': 'breakdown',
          'Taux de pannes': {
            field: 'breakdownRate',
            callback: value => parseFloat(value).toFixed(2)
          },
          'Pannes clotûrées': 'breakdownClosed',
          'Pannes en cours': 'breakdownInProgress',
          'Interventions': 'intervention',
          'Taux d\'interventions': {
            field: 'interventionRate',
            callback: value => parseFloat(value).toFixed(2)
          },
          'Interventions Clotûrées': 'interventionClosed',
          'Interventions En Cours': 'interventionInProgress',
          'Fournisseur': 'supplier'
        }
      }
    },

    async created() {
      this.$showLoader()
      if (!this.currentFavorite) {
        const tmpCurrentFavorite = this.favoriteByIndex(parseInt(this.id))
        this.SET_CURRENT_FAVORITE(tmpCurrentFavorite)
        await this.fetchFavoriteIndicators({ favorite: this.currentFavorite })
      }
      await this.fetchChildrens({ nomenclature: this.currentFavorite.nomenclature })
      this.$hideLoader()
    },

    beforeDestroy() {
      this.resetChildrensIndicatorsIfExists('allSector')
    },

    computed: {
      ...mapGetters({
        currentFavorite: 'favorites/currentFavorite',
        period: 'period/period',
        favoriteIndicators: 'indicators/getIndicatorsByFavorite',
        favoriteByIndex: 'favorites/favoriteByIndex'
      }),

      tree() {
        return Object.keys(this.indicators).reduce((acc, key) => {
          if (this.indicators[key] && this.indicators[key].length > 0) acc[key] = this.indicators[key]
          return acc
        }, {})
      },

      currentFavoriteIndicators() {
        return this.favoriteIndicators({ favId: this.currentFavorite.id })
      },

      getCurrentDate() {
        return DateTime.local().toFormat('dd\'-\'LL\'-\'yyyy\'')
      }
    },

    watch: {
      period: {
        async handler(fresh, old = {}) {
          if (fresh === old || (+fresh.startDate === +old.startDate && +fresh.endDate === +old.endDate)) return
          this.resetChildrensIndicatorsIfExists('allSector')
          try {
            await Promise.all([
              this.fetchChildrens({ nomenclature: this.currentFavorite.nomenclature }),
              this.fetchFavoriteIndicators({ favorite: this.currentFavorite })
            ])
          } catch (err) {
            console.error(err)
            Vue.toasted.error('Erreur lors de la récupération des données')
          }
        }
      }
    },

    methods: {
      ...mapMutations('favorites', ['SET_CURRENT_FAVORITE']),
      ...mapActions('indicators', ['fetchFavoriteIndicators']),

      onNomCardClicked({ level, sid }) {
        if (this.selectedNoms[level] === sid) return
        const nextNomenclature = { level, items: [{ sid }] }
        this.resetChildrensIndicatorsIfExists(level)
        this.selectedNoms[level] = sid
        this.fetchChildrens({ nomenclature: nextNomenclature })
      },

      async fetchChildrens({ nomenclature }) {
        this.$showLoader()

        let searchParams = { startDate: this.period.start.toISODate(), endDate: this.period.end.toISODate() }

        switch (nomenclature ? nomenclature?.level : this.currentFavorite.nomenclature?.level) {
        case 'univers':
        case 'universes':
          searchParams.nomenclatureIn = 'univers'
          searchParams.nomenclatureOut = 'market'
          break
        case 'market':
        case 'markets':
          searchParams.nomenclatureIn = 'market'
          searchParams.nomenclatureOut = 'family'
          break
        case 'family':
        case 'families':
          searchParams.nomenclatureIn = 'family'
          searchParams.nomenclatureOut = 'product'
          break

        default:
          searchParams.nomenclatureIn = 'allSector'
          searchParams.nomenclatureOut = 'univers'
          break
        }

        if (nomenclature?.items) searchParams.nomenclature = nomenclature?.items?.map(nom => nom.sid)
        if (this.currentFavorite.brand?.length) searchParams.brand = this.currentFavorite.brand?.map(b => b.id)
        if (this.currentFavorite.supplier?.length) searchParams.supplier = this.currentFavorite.supplier?.map(s => s.id)

        const token = await this.$user.getIdToken()

        const childrens = await http
          .extend({ headers: { Authorization: `Bearer ${token}` } })
          .get('indicators/childrens', { searchParams })
          .json()
          .then(res => res ? res : [])
          .catch(err => {
            console.error(err)
            Vue.toasted.error('Erreur lors de la récupération des indicateurs enfants de nomenclature')
          })

        this.indicators[searchParams.nomenclatureOut] = formatChildrensIndicatorsList(childrens).sort((a, b) => a.labelNom.localeCompare(b.labelNom))

        this.$hideLoader()
      },

      resetChildrensIndicatorsIfExists(currentLevel) {
        let nextNomLevel = getNextNomLevel(currentLevel)
        while (nextNomLevel && this.indicators[nextNomLevel]) {
          this.indicators[nextNomLevel].splice(0)
          this.selectedNoms[nextNomLevel] = null
          nextNomLevel = getNextNomLevel(nextNomLevel)
        }
      },

      setTmpNomProductsLevel(level) {
        this.tmpNomProductsLevel = level
      },

      async fetchExcelNomProductsExport() {
        const nomLevel = getParentNomLevel(this.tmpNomProductsLevel)

        if (nomLevel === 'univers' && !this.selectedNoms.univers && this.currentFavorite.nomenclature.items.length > 1) {
          return Vue.toasted.info('Il est impossible d\'exporter plusieurs univers')
        }

        this.$showLoader()

        const nomenclature = this.selectedNoms[nomLevel] ? {
          level: nomLevel,
          items: [{ sid: this.selectedNoms[nomLevel] }]
        } : this.currentFavorite.nomenclature

        let searchParams = {startDate: this.period.start.toISODate(), endDate: this.period.end.toISODate()}

        switch (nomenclature ? nomenclature?.level : this.currentFavorite.nomenclature?.level) {
          case 'univers':
            searchParams.nomenclatureIn = 'univers'
            searchParams.nomenclatureOut = 'product'
            break
          case 'market':
            searchParams.nomenclatureIn = 'market'
            searchParams.nomenclatureOut = 'product'
            break
        }

        if (nomenclature?.items) searchParams.nomenclature = nomenclature?.items?.map(nom => nom.sid)
        if (this.currentFavorite.brand?.length) searchParams.brand = this.currentFavorite.brand?.map(b => b.id)
        if (this.currentFavorite.supplier?.length) searchParams.supplier = this.currentFavorite.supplier?.map(s => s.id)

        const token = await this.$user.getIdToken()

        const data = await http
          .extend({ headers: { Authorization: `Bearer ${token}` } })
          .get('indicators/childrens', { searchParams })
          .json()
          .then(res => res ? formatChildrensIndicatorsList(res) : [])
          .catch(err => {
            console.error(err)
            Vue.toasted.error('Erreur lors de la récupération des produits à exporter.')
          })

        this.$hideLoader()
        return data
      }
    }
  }
</script>

<style lang="scss" scoped>
  .details {
    padding: 3rem 4rem;

    .header {
      .go-back {
        display: flex;
        align-items: center;
        cursor: pointer;

        .title {
          margin-left: 0.5rem;
          font-weight: 600;
          font-size: 18px;
        }
      }

      .filters {
        display: flex;
        justify-content: space-between;
        margin-top: 3rem;
        align-items: center;
      }
    }

    .separator {
      content: '';
      display: block;
      width: 100%;
      border-bottom: 1px solid $app-grey-light;
      margin: 2rem 0;
    }

    .level {
      &:not(:first-child) {
        .nom-label { margin-top: 1rem; }
      }

      .arrow-down {
        margin-top: 1rem;
        margin-left: 8rem;
        color: $app-grey-light;
      }

      .nom-label {
        font-family: $primary-font--medium;
        font-weight: 600;
        font-size: 24px;
        display: flex;
        align-items: center;

        .download-btn {
          background-color: $primary-color;
          color: white;
          font-size: 14px;
          margin-left: 2rem;
          padding: 0.7rem 2rem;
          border-radius: 8px;
          font-weight: 500;
          cursor: pointer;

          &:hover {
            background-color: #BF8B6F;
          }

          .download { margin-left: 1rem; }
        }
      }

      .cards {
        overflow-x: auto;
        display: flex;
        padding: 3rem 0 1rem 0;

        .nom-card:not(:first-child) {
          margin-left: 1.5rem;
        }
      }
    }
  }
</style>
