import { DateTime } from 'luxon'

const state = {

  period: {
    start: DateTime.local().startOf('month').plus({ month: -1 }),
    end: DateTime.local().startOf('month').plus({ days: -1 })
  }
}

const getters = {
  period: state => state.period
}

const mutations = {
  SET_PERIOD: (state, value) => (state.period = value)
}

const actions = {}

export default {
  state,
  getters,
  mutations,
  actions
}
