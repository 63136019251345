import Vue from 'vue'

export const formatChildrensIndicatorsList = childrensIndicatorsList =>
  childrensIndicatorsList.reduce((acc, children) => {
    acc.push(formatIndicators(children))
    return acc
  }, [])

export const formatIndicators = indicators => ({
  ...indicators,
  breakdownRate: indicators.breakdown === 0 || indicators.sales === 0 ? 0 : (indicators.breakdown / indicators.sales) * 100 || null,
  interventionRate: indicators.intervention === 0 || indicators.sales === 0 ? 0 : (indicators.intervention / indicators.sales) * 100 || null,
  breakdownInProgress: indicators.breakdown === 0 || indicators.sales === 0 ? 0 : indicators.breakdown - indicators.breakdownClosed || null,
  interventionInProgress:
    indicators.intervention === 0 || indicators.sales === 0 ? 0 : indicators.intervention - indicators.interventionClosed || null
})

export const getNextNomLevel = nomenclature => {
  switch (nomenclature) {
    case 'allSector':
      return 'univers'
    case 'univers':
      return 'market'
    case 'market':
      return 'family'
    case 'family':
      return 'product'
    default:
      return null
  }
}

export const getParentNomLevel = nomenclature => {
  switch (nomenclature) {
    case 'univers':
      return 'allSector'
    case 'market':
      return 'univers'
    case 'family':
      return 'market'
    case 'product':
      return 'family'
    default:
      return null
  }
}

export const getNomLevelLabelById = nomId => {
  switch (nomId) {
    case 1:
      return 'global'
    case 31:
      return 'universes'
    case 3:
      return 'markets'
    case 5:
      return 'categories'
    case 6:
      return 'families'
    case 9:
      return 'products'
  }
}

export const generateAlphabet = () => {
  let alphabet = []
  let begin = 'a'.charCodeAt(0)
  let last = 'z'.charCodeAt(0)
  for (; begin <= last; ++begin) {
    alphabet.push(String.fromCharCode(begin))
  }
  return alphabet
}

export const sendSuccessToast = message => {
  Vue.toasted.clear()
  Vue.toasted.success(message, {
    theme: 'toasted-primary',
    position: 'bottom-center',
    duration: 7000,
    fullWidth: true,
    singleton: true,
    keepOnHover: true,
    fitToScreen: true
  })
}

export const sendErrorToast = err => {
  Vue.toasted.clear()
  Vue.toasted.error(err, {
    theme: 'toasted-primary',
    position: 'bottom-center',
    duration: 7000,
    fullWidth: true,
    singleton: true,
    keepOnHover: true,
    fitToScreen: true
  })
}
