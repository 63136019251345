import { DateTime } from 'luxon'
import Vue from 'vue'

Vue.filter('capitalize', val => {
  return val && val.toLowerCase().replace(/^\w/, c => c.toUpperCase())
})

Vue.filter('dateFormatFr', val => {
  return val ? `${ DateTime.fromISO(val).setLocale('fr').toFormat('cccc dd LLLL yyyy') }` : ''
})

Vue.filter('dateFormatFrWithPrefix', (val, prefix) => {
  return val ? `${ prefix } ${ DateTime.fromISO(val).setLocale('fr').toFormat('cccc dd LLLL yyyy') }` : ''
})

Vue.filter('dateFormatShort', val => {
  return val ? `${ DateTime.fromISO(val).setLocale('fr').toFormat('dd\'/\'LL\'/\'yyyy') }` : ''
})

Vue.filter('numberWithSpaces', val => {
  if (val === null) return 'N/A'
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
})

Vue.filter('formatDecimals', (val, decimalNb) => {
  return val !== undefined && val !== null ? `${ val.toFixed(decimalNb) }%` : 'N/A'
})

Vue.filter('formatValue', val => {
  return val !== undefined && val !== null ? val > 1000 ? Intl.NumberFormat('en-US', {
    notation: 'compact',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(val) : val : 'N/A'
})

Vue.filter('formatNb', val => {
  const lengthOfVal = String(val).length
  const round = parseInt('1'.padEnd(lengthOfVal, '0'))
  return Math.ceil(val / round) * round
})
