import Vue from 'vue'
import Vuex from 'vuex'
import favorites from './favorites.store'
import indicators from './indicators.store'
import period from './period.store'
import referential from './referential.store'

Vue.use(Vuex)

const stores = {
  referential,
  favorites,
  indicators,
  period
}

const state = {}
const getters = {}
const mutations = {}
const actions = {}

const modules = Object.entries(stores).reduce((modules, [name, store]) => {
  modules[name] = {
    namespaced: true,
    ...store
  }
  return modules
}, {})

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules
})
