<template>
  <transition mode="out-in" name="fade">
    <loading-card v-if="isLoading" />
    <v-card
      v-else
      :edit-mode="editMode"
      :favorite="favorite"
      :index="index"
      :indicators="favIndicators"
      :trash-mode="trashMode"
      @reload="fetch" />
  </transition>
</template>

<script>
  import Card from '@/components/card/Card'
  import LoadingCard from '@/components/card/LoadingCard'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'Index',
    components: { 'v-card': Card, LoadingCard },

    props: {
      favorite: {
        type: Object,
        required: true
      },
      editMode: {
        type: Boolean,
        default: false
      },
      trashMode: {
        type: Boolean,
        default: false
      },
      index: {
        type: Number,
        required: true
      }
    },

    data() {
      return {
        isLoading: false
      }
    },

    computed: {
      ...mapGetters({ period: 'period/period', getIndicatorsByFavorite: 'indicators/getIndicatorsByFavorite' }),

      favIndicators() {
        return this.favorite && this.favorite.id ? this.getIndicatorsByFavorite({ favId: this.favorite.id }) : null
      }
    },

    watch: {
      period: {
        immediate: true,
        handler(fresh, old = {}) {
          if (fresh === old || (+fresh.startDate === +old.startDate && +fresh.endDate === +old.endDate)) return
          this.fetch()
        }
      }
    },

    methods: {
      ...mapActions('indicators', ['fetchFavoriteIndicators']),

      async fetch() {
        this.isLoading = true
        this.isLoadingError = false
        try {
          await this.fetchFavoriteIndicators({ favorite: this.favorite })
          this.isLoading = false
        } catch (error) {
          this.isLoading = false
          console.error(error)
        }
      }
    }
  }
</script>
