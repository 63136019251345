import '@/components/base'
import '@/filters/filters'
import '@/plugins/app.plugin'
import '@/registerServiceWorker'
import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import store from './store/index'

Vue.config.productionTip = false

export default function createVueApp({ firebaseUser, user, roles }) {
  addPropertiesOnVuejsPrototype({ firebaseUser, user, roles })

  return new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app')
}

function addPropertiesOnVuejsPrototype({ firebaseUser, user, roles }) {
  Vue.prototype.$user = firebaseUser
  Vue.prototype.$userInfos = user
  Vue.prototype.$roles = roles
}
