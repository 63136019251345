<template>
  <div class="dropdown" @click="showDropdown = !showDropdown">
    <div class="dropdown-selected">
      <span> {{ selectedValue }}</span>
      <v-icon :class="{ 'rotate-180': showDropdown }">chevron-down</v-icon>
    </div>
    <ul v-if="showDropdown" class="dropdown-list">
      <li v-for="(item, key) in list"
          :key="key"
          :class="{ 'is-selected': item === selectedValue }"
          @click="choiceOfItem(key)">
        <span v-once> {{ item }} </span>
        <v-icon v-if="item === selectedValue" class="icon">check</v-icon>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'VDropdown',
    props: {
      list: {
        type: Object,
        default: null
      },
      selectedValue: {
        type: String,
        default: null
      }
    },
    data() {
      return {
        showDropdown: false
      }
    },
    methods: {
      choiceOfItem(item) {
        this.$emit('update-dropdown', item)
      }
    }
  }
</script>

<style lang="scss" scoped>
  $width : 10em;

  .dropdown {
    width     : $width;
    cursor    : pointer;
    font-size : 0.9em;
    .dropdown-selected {
      @extend .btn;
      height          : 3.5em;
      display         : flex;
      align-items     : center;
      justify-content : space-between;
      padding         : 1em;

      mark {
        flex           : 1;
        text-transform : uppercase;
        background     : transparent;
        font-weight    : 600;
      }
    }

    .dropdown-list {
      position      : absolute;
      top           : 0;
      width         : $width;
      border        : 1px solid $app-grey-light;
      border-radius : $radius-btn;
      background    : $app-white;

      li {
        display         : flex;
        justify-content : space-between;
        align-items     : center;
        padding         : 0.5em 0.75em;

        &.is-selected {
          font-weight : 600;
        }
      }
    }
  }
</style>
