<template>
  <div class="v-notifications">
    <header>
      Notifications
    </header>
    <div v-if="hasNotifications" class="notifications">
      <div
        v-for="(notif, index) of notifications"
        :key="'notif' + index"
        class="notification-wrapper"
        :class="{ seen: notif.seen }">
        <div class="date">{{ notif.startDate | toDate }}</div>
        <div class="notification" @click="markAsSeen(notif)">
          <v-icon class="type" :class="[notif.type]">
            {{ notif.type === 'info' ? 'rss' : 'triangle' }}
          </v-icon>
          <div class="title">{{ notif.title }}</div>
          <div class="message" v-html="notif.message" />
        </div>
      </div>
    </div>
    <div v-else class="no-data">
      <div class="illustration">
        <img src="@/assets/illustrations/empty.svg" alt="">
      </div>
      <div class="content">
        <p class="message">Vous n'avez aucune notification.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  filters: {
    toDate(millis) {
      return new Date(millis).toLocaleDateString()
    }
  },
  computed: {
    ...mapGetters('notifications', ['notifications']),
    hasNotifications() {
      return this.notifications && this.notifications.length
    }
  },

  methods: {
    ...mapActions('notifications', ['markAsSeen'])
  }
}
</script>

<style lang="scss" scoped>
.v-notifications {
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;
  padding: 2.5em 1em 1em 2.5em;
}

header {
  margin-bottom: 1em;
  font-size: 1.5em;
  color: $secondary-color;
  font-weight: 600;
}

.notifications {
  grid-row: 2 / 3;
  overflow: auto;
}

.notification-wrapper {
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(1em - 1px);
    width: 1px;
    border-left: 1px solid #ddd;
  }

  .date {
    font-size: 1rem;
    text-align: right;
    padding: 0 0 0.25em;
  }

  &.seen {
    opacity: 0.5;

    .date {
      color: $app-grey-light;
    }

    .notification {
      box-shadow: none;

      .type {
        color: $app-grey-light;
      }
    }
  }
}

.notification {
  position: relative;
  background-color: $app-white;
  font-size: 1.6rem;
  box-shadow: 0 3px 1em rgba(#000, 0.05);
  border-radius: 2px;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 2px;
    $s: 1rem;
    width: $s;
    height: $s;
    background-color: #fff;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
  }

  .type {
    position: absolute;
    top: 50%;
    bottom: 0;
    left: -2rem;
    transform: translateX(-50%) translateY(-50%);
    background-color: $app-grey-light;
    border-radius: 50%;
    font-size: 2rem !important;

    &.info {
      color: $primary-color;
    }

    &.alert {
      color: $app-red;
    }
  }

  .title {
    font-size: 0.8em;
    padding: 1rem;
  }

  .message {
    padding: 0 1rem 1em;
  }
}

.no-data {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 3em;

  .illustration {
    width: 100%;
    height: auto;

    img {
      width: 100%;
      height: auto;
      max-height: 20em;
    }
  }

  .content {
    width: 70%;
    display: flex;
    justify-items: center;
    align-items: center;
    flex-direction: column;
  }

  .message {
    width: 100%;
    margin: 1em auto;
    text-align: center;
    font-size: 0.9em;
  }
}
</style>
