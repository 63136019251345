import bus from '@/common/bus'
import { Settings } from 'luxon'
import VueTooltip from 'v-tooltip'
import Vue from 'vue'
import JsonExcel from 'vue-json-excel'
import VueToasted from 'vue-toasted'
import VuejsDialog from 'vuejs-dialog'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'

Settings.defaultLocale = 'fr'

const plugin = {
  install(Vue) {
    Vue.prototype.$bus = bus

    Vue.prototype.$showLoader = function() {
      bus.$emit('show-loader')
    }

    Vue.prototype.$hideLoader = function() {
      bus.$emit('hide-loader')
    }
  }
}

Vue.use(plugin)
Vue.use(VueTooltip)
Vue.use(VueToasted, {
  position: 'bottom-right',
  duration: 2500,
  className: 'v-toast',
  singleton: true
})
Vue.use(VuejsDialog)
Vue.component('downloadExcel', JsonExcel)
