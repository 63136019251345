import http from '@/http'
import Vue from 'vue'

const nomenclatureHierarchy = {
  all: 'universes',
  universes: 'markets',
  markets: 'families'
}

const state = {
  nomenclature: {},
  brands: {},
  suppliers: {}
}

const buildUrl = (prefix, letter, limit, page, search, cnuf = null) => {

  let begin = limit !== null ? (page - 1) * limit : null
  let end = limit !== null ? page * limit : null

  const params = [
    limit ? `begin=${ begin }` : null,
    limit ? `end=${ end }` : null,
    letter ? `letter=${ letter }` : null,
    search ? `search=${ search }` : null,
    cnuf ? `cnuf=${ cnuf }` : null,
    `app=tif`
  ].filter(entry => entry)
  return `${ prefix }?${ params.join('&') }`
}

const getters = {
  nomenclatureRoot: () => {
    return {
      label: 'Tous les univers',
      sid: null,
      type: 'all',
      asChildren: true
    }
  },

  nomenclatureHierarchy: () => nomenclatureHierarchy,

  nomenclatureByParent: state => (sid, type = nomenclatureHierarchy.all) => {
    const childrenLevel = nomenclatureHierarchy[type]
    if (childrenLevel) {
      let children = state.nomenclature[0][childrenLevel]
      return children.filter(child => !sid || child.parentSid === sid).sort((a, b) => (a.label < b.label ? -1 : 1))
    }
    return null
  },

  nomenclatureByChild: state => (parentId, type) => {
    return state.nomenclature[0][type].filter(el => el.sid === parentId)
  },

  brands: state => state.brands,
  suppliers: state => state.suppliers
}

const mutations = {
  setReferential(state, nomenclature) {
    state.nomenclature = nomenclature
  },

  setBrands(state, brands) {
    state.brands = brands
  },

  setSuppliers(state, suppliers) {
    state.suppliers = suppliers
  }
}

const actions = {
  async fetchReferential({ commit }) {
    const token = await Vue.prototype.$user.getIdToken()

    const data = await http
      .extend({ headers: { Authorization: `Bearer ${ token }` } })
      .get('referential/nomenclature')
      .json()

    commit('setReferential', data)
  },

  async fetchBrands({ commit }, { letter, limit, page, search }) {
    const url = buildUrl('referential/brands', letter, limit, page, search)

    const token = await Vue.prototype.$user.getIdToken()

    const data = await http
      .extend({ headers: { Authorization: `Bearer ${ token }` } })
      .get(url)
      .json()

    commit('setBrands', data)
  },

  async fetchSuppliers({ commit }, { letter, limit, page, search, cnuf }) {
    const url = buildUrl('referential/suppliers', letter, limit, page, search, cnuf)

    const token = await Vue.prototype.$user.getIdToken()

    const data = await http
      .extend({ headers: { Authorization: `Bearer ${ token }` } })
      .get(url)
      .json()

    commit('setSuppliers', data)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
