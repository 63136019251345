<template>
  <div v-show="isVisible$" class="popup">
    <transition name="fade">
      <div v-if="isVisible" class="background" @click="close" />
    </transition>
    <transition name="slide">
      <div v-if="isVisible" class="v-content">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    name: 'Popup',
    props: {
      isVisible: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        isVisible$: false
      }
    },

    watch: {
      isVisible(isVisible) {
        if (isVisible) {
          this.isVisible$ = true
        } else {
          setTimeout(() => this.isVisible$ = false, 200)
        }
      }
    },

    methods: {
      close() {
        this.$emit('update:isVisible', false)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .slide {
    &-enter-active, &-leave-active {
      transition: transform 200ms ease-in-out;
    }

    &-enter, &-leave-to {
      transform: translateY(100%);
    }
  }

  .fade {
    &-enter-active, &-leave-active {
      transition: opacity 200ms ease-in-out;
    }

    &-enter, &-leave-to {
      opacity: 0;
    }
  }

  .popup {
    position: fixed;
    z-index: 999999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .background {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(#000, 0.8);
    }

    .v-content {
      width: 60rem;
      padding: 1em;
      background: $app-white;
      z-index: 1;
      position: relative;
      display: inline-flex;
      justify-content: center;
      overflow: hidden;
      border-radius: 1em;
    }
  }
</style>
