<template>
  <div class="v-nomenclature-selector">
    <div class="top-header">
      <v-icon key="back"
              class="back"
              font-size="2rem"
              v-if="breadcrumb.length > 1"
              size="2.5em"
              @click="back">
        chevron-left
      </v-icon>
      <v-icon key="close"
              class="back"
              font-size="2rem"
              v-else
              size="2.5em"
              @click="close">
        x
      </v-icon>
      <span class="label"> {{ node.label }} </span>
    </div>
    <div class="search-container">
      <search @search-change="(value) => search = value" />
    </div>
    <div class="breadcrumb-container">
      <breadcrumb :breadcrumb="breadcrumb" :something-selected="somethingSelected" @nav-breadcrumb="navBreadcrumb" />
    </div>

    <div class="list-label">
      Liste des {{ $t(`context.nomenclature.types.${nomenclatureHierarchy[node.type]}.plural`) }}:
    </div>

    <ul>
      <li v-show="node.children.length > 0 && node.children[0].type !== 'universes'"
          :class="{ 'something-selected': somethingSelected ,selected : selectedAll}"
          @click="checkAll">
        <v-icon class="v-check" size="3em">check</v-icon>
        <span class="label check-all">Tous séléctionner</span>
      </li>

      <li
        v-for="(child, index) of node.children"
        :key="index + child.label"
        :class="{leaf: somethingSelected || child.type === 'families', selected: checks[index], 'something-selected': somethingSelected}">
        <v-icon class="v-check" size="3em" @click="check(index)">check</v-icon>
        <span class="label" @click="gotoChild(child, index)">{{ child.label }}</span>
        <v-icon class="icon" size="3em" @click="gotoChild(child)">chevron-right</v-icon>
      </li>
    </ul>
    <transition name="slideup">
      <button v-if="somethingSelected" class="btn fixed-bottom" @click="addNomenclature">Valider</button>
    </transition>
  </div>
</template>

<script>
  import breadcrumb from '@/components/context/Breadcrumb'
  import { mapGetters } from 'vuex'

  const universesConcerned = [6, 7, 10, 2]

  export default {
    components: { breadcrumb },
    model: {
      prop: 'value',
      event: 'change'
    },

    props: {
      value: {
        type: Object,
        default: null
      },
      context: {
        type: Array,
        default: null
      }
    },
    data() {
      return {
        currentNomenclature: {},
        breadcrumb: [],
        checks: [],
        search: '',
        showRemovePopup: false,
        edit: false,
        selectedAll: false,
        init: true
      }
    },

    computed: {
      ...mapGetters({
        nomenclatureByParent: 'referential/nomenclatureByParent',
        nomenclatureByChild: 'referential/nomenclatureByChild',
        nomenclatureRoot: 'referential/nomenclatureRoot',
        nomenclatureHierarchy: 'referential/nomenclatureHierarchy'
      }),

      node() {
        const type = this.currentNomenclature.type
        const sid = this.currentNomenclature.sid
        let children = this.nomenclatureByParent(sid, type) || []
        children = type === 'all' ? children.filter(el => universesConcerned.includes(el.sid)) : children

        return {
          ...this.currentNomenclature,
          children: children.filter(el => el.label.toLowerCase().includes(this.search.toLowerCase()))
        }
      },

      somethingSelected() {
        return this.checks.filter(check => check).length
      }
    },

    watch: {
      node(node) {
        if (this.edit === true && this.init === true) {
          let contextSid = []
          this.value['items'].map(el => contextSid.push(el.sid))
          this.node.children.forEach(el => contextSid.includes(el.sid) ? this.checks.push(true) : this.checks.push(false))
          this.init = false
        } else {
          this.checks = node.children.map(() => false)
        }
      },

      value(value) {
        this.value = value
      }
    },

    async created() {
      if (this.value && this.value.level !== 'universes') {
        this.edit = true
        let asChildren = true
        const type = this.$t(`context.nomenclature.types.${ this.value.level }.parent`)
        const { label, sid, parentSid } = this.nomenclatureByChild(parseInt(this.value.items[0].parent), type)[0]

        if (type === 'families') asChildren = false

        this.currentNomenclature = {
          label: label,
          sid: sid,
          type: type,
          asChildren: asChildren
        }

        this.buildBreadcrumb(parentSid)

      } else {
        this.currentNomenclature = {
          ...this.nomenclatureRoot
        }
        this.addToBreadcrumb()
      }
    },

    methods: {
      async gotoChild(child, index) {
        if (child.type === undefined || child.type === 'families' || this.somethingSelected) {
          this.check(index)
          return
        }
        this.currentNomenclature = { ...child }
        this.addToBreadcrumb()
      },

      addToBreadcrumb() {
        this.$nextTick(() => {
          this.breadcrumb.push(this.node)
        })
      },

      back() {
        if (!this.somethingSelected) {
          this.breadcrumb.pop()
          this.currentNomenclature = this.breadcrumb[this.breadcrumb.length - 1]
        } else {
          this.checks = this.node.children.map(() => false)
        }
      },

      check(index) {
        this.selectedAll = false
        const check = this.checks[index]
        this.checks.splice(index, 1, !check)
      },

      close() {
        this.$emit('close')
      },

      navBreadcrumb(node) {
        const indexNode = this.breadcrumb.indexOf(node) + 1
        if (indexNode !== this.breadcrumb.length) {
          this.breadcrumb.splice(indexNode, this.breadcrumb.length)
          this.currentNomenclature = node
        }
      },

      buildBreadcrumb(parentId) {
        let type = this.$t(`context.nomenclature.types.${ this.node.type }.parent`)
        this.breadcrumb.push(this.node)

        while (type !== 'all') {
          let parent = this.nomenclatureByChild(parentId, type)[0]
          this.breadcrumb.push(parent)
          type = this.$t(`context.nomenclature.types.${ type }.parent`)
          parentId = parent.parentSid
        }
        this.breadcrumb.push(this.nomenclatureRoot)
        this.breadcrumb.reverse()
      },

      addNomenclature() {
        const parent = this.node.sid
        const level = this.node.children[0].type
        let value = { level: null, items: [] }

        if (this.selectedAll) {
          value.level = this.currentNomenclature.type
          value.items.push({
            label: this.currentNomenclature.label,
            parent: this.currentNomenclature.parentSid,
            sid: this.currentNomenclature.sid
          })
        } else {
          value.level = level
          value.items = this.checks.reduce((items, check, index) => {
            if (check) {
              const { sid, label } = this.node.children[index]
              items.push({
                sid, label, parent
              })
            }
            return items
          }, [])
        }
        this.$emit('update-nomenclature', value)
        this.close()
      },

      hasSameLevel(level) {
        return level !== this.value.level
      },

      deletePreviousContext() {
        this.$emit('delete-nomenclature')
        this.addNomenclature()
      },

      checkAll() {
        this.selectedAll = !this.selectedAll
        this.checks = this.node.children.map(() => this.selectedAll)
      }
    }
  }

</script>

<style lang="scss" scoped>

  .v-nomenclature-selector {
    font-size: 1.6rem;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    width: 100%;
    user-select: none;

    & > .search-container {
      margin: 1em;
    }

    .top-header {
      flex-grow: 0;
      flex-shrink: 0;
      position: relative;
      display: flex;
      align-items: center;
      min-height: 8rem;
      font-size: 1.8rem;
      font-weight: bold;
      text-align: center;
      background: $primary-color;
      color: #FFF;

      .label {
        padding: 1em;
        margin: auto;
        display: inline-flex;
        align-items: center;
        align-self: stretch;
      }

      .back {
        position: absolute;
        left: 0;
        cursor: pointer;
      }
    }

    .search {
      padding: 0 1em;
    }

    .list-label {
      padding: 1em;

      .v-icon {
        margin-right: 1em;
      }
    }

    ul {
      box-shadow: 0 3px 1em rgba(#000, 0.1);
      font-size: 1.4rem;

      li {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        overflow: hidden;
        cursor: pointer;
        border-bottom: 1px solid #F5F5F5;
        background-color: #FFF;

        .v-check {
          position: relative;
          z-index: 0;
          cursor: pointer;
          color: $app-grey;
          background-color: #F5F5F5;
        }

        .label {
          position: relative;
          flex-grow: 1;
          padding-left: 1em;
          white-space: pre-wrap;
          align-self: stretch;
          display: flex;
          align-items: center;

          &:not(.check-all) {
            text-transform: uppercase;
          }
        }

        .icon {
          position: relative;
          flex-grow: 0;
          flex-shrink: 0;
        }

        &.leaf {
          .icon {
            color: transparent;
            pointer-events: none;
          }
        }

        &.something-selected:not(.selected) {
          background-color: transparent !important;

          .v-check {
            background-color: transparent;
          }
        }

        &.selected {
          box-shadow: 0 3px 6px rgba(#000, 0.05);
          color: $primary-color;
          background-color: #FFF;
          //box-shadow       : 0 3px 1em rgba(#000, 0.05);
          border-color: #FFF;

          .v-check {
            color: inherit;
            background-color: transparent;

            :global(& svg) {
              stroke-width: 3px;
            }
          }

          .label {
            box-shadow: none;
            border: none;
          }

          .icon {
            border: none;
          }
        }
      }
    }

    .breadcrumb-container {
      padding: 0.5em 1em 0.2em;
    }
  }

</style>
