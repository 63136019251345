<template>
  <div class="v-card">
    <div class="v-card-context" @click="hasData && navToDetails">
      <div class="v-card-context-title"> {{ nameFavorite }}</div>
      <div v-tooltip="suppliers" class="v-card-context-row">
        <v-icon font-size="1.3em">palet-truck</v-icon>
        <span>{{ suppliers }}</span>
      </div>
      <div v-tooltip="brands" class="v-card-context-row">
        <v-icon font-size="1.3em">label</v-icon>
        <span>{{ brands }}</span>
      </div>
      <div v-tooltip="nomenclature" class="v-card-context-row">
        <v-icon font-size="1.3em">tree</v-icon>
        <span>{{ nomenclature }}</span>
      </div>
    </div>
    <div v-if="hasData" :class="[{'alert' : isAlert}, 'v-card-values']" @click="navToDetails">
      <div class="v-card-values-indicator sales">
        {{ $t('KPIS.sales') }}
        <span class="value">{{ indicators.sales | formatValue }}</span>
      </div>
      <div class="v-card-values-indicator breakdown">
        {{ $t('KPIS.breakdown') }}
        <span class="value">{{ indicators.breakdown | formatValue }}</span>
      </div>
      <div class="v-card-values-indicator breakdown-rate">
        {{ $t('KPIS.breakdownRate') }}
        <span class="value">{{ indicators.breakdownRate | formatDecimals(2) }}</span>
      </div>
      <div class="v-card-values-indicator breakdown-in-progress">
        {{ $t('KPIS.breakdownInProgress') }}
        <span class="value">{{ indicators.breakdownInProgress | formatValue }}</span>
      </div>
      <div class="v-card-values-indicator breakdown-closed">
        {{ $t('KPIS.breakdownClosed') }}
        <span class="value">{{ indicators.breakdownClosed | formatValue }}</span>
      </div>
      <div class="v-card-values-indicator intervention">
        {{ $t('KPIS.intervention') }}
        <span class="value">{{ indicators.intervention | formatValue }}</span>
      </div>
      <div class="v-card-values-indicator intervention-rate">
        {{ $t('KPIS.interventionRate') }}
        <span class="value">{{ indicators.interventionRate | formatDecimals(2) }}</span>
      </div>
      <div class="v-card-values-indicator intervention-in-progress">
        {{ $t('KPIS.interventionInProgress') }}
        <span class="value">{{ indicators.interventionInProgress | formatValue }}</span>
      </div>
      <div class="v-card-values-indicator intervention-closed">
        {{ $t('KPIS.interventionClosed') }}
        <span class="value">{{ indicators.interventionClosed | formatValue }}</span>
      </div>
    </div>
    <div v-else class="v-card-no-data">
      <span>Erreur de chargement</span>
      <img class="no-data-illu" src="../../assets/illustrations/no-data.svg">
      <button class="btn primary" @click="$emit('reload')">Recharger</button>
    </div>
    <div v-if="editMode || trashMode" class="v-card-swipper">
      <v-icon v-if="editMode"
              key="edit"
              class="icon"
              font-size="2em"
              @click="edit">
        edit
      </v-icon>
      <v-icon v-if="trashMode"
              key="trash"
              class="icon"
              font-size="2em"
              @click="deleteCard">
        trash
      </v-icon>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapMutations } from 'vuex'

  export default {
    name: 'Card',

    props: {
      favorite: {
        type: Object,
        default: null
      },
      editMode: {
        type: Boolean,
        default: false
      },
      trashMode: {
        type: Boolean,
        default: false
      },
      indicators: {
        type: Object,
        default: null
      },
      index: {
        type: Number,
        default: 0
      }
    },

    computed: {
      nameFavorite() {
        return this.favorite.name !== null ? this.favorite.name : `Carte personnalisée #${ this.index + 1 }`
      },

      nomenclature() {
        return this.favorite.nomenclature && this.favorite.nomenclature.items ? this.favorite.nomenclature.items.map(item => item.label).join(', ') : 'Tous les univers'
      },

      brands() {
        return this.favorite.brand ? this.favorite.brand.map(item => item.label).join(', ') : 'Toutes les marques'
      },

      suppliers() {
        return this.favorite.supplier ? this.favorite.supplier.map(item => item.label).join(', ') : 'Tous les fournisseurs'
      },

      isAlert() {
        if (this.hasData) return this.indicators.breakdownRate >= 5 || this.indicators.interventionRate >= 5
      },

      hasData() {
        return this.indicators && Object.values(this.indicators).some(val => val)
      }
    },
    methods: {
      ...mapMutations('favorites', ['SET_CURRENT_FAVORITE']),
      ...mapActions({
        removeFavorite: 'favorites/removeFavorite',
        editFavorite: 'favorites/editFavorite'
      }),

      navToDetails() {
        this.SET_CURRENT_FAVORITE(this.favorite)
        this.$router.push({ name: 'details', params: { id: this.index } })
      },

      edit() {
        this.$router.push({ name: 'context', params: { id: this.index } })
      },

      deleteCard() {
        this.$dialog.confirm(this.$t('dialog.deleteValidation'), { okText: 'Supprimer', cancelText: 'Annuler' })
            .then(dialog => this.removeFavorite({ id: this.index }))
      }
    }
  }
</script>

<style lang="scss" scoped>
  .v-card {
    display: flex;
    justify-content: space-between;
    border: 1px solid $app-grey-light;
    border-radius: 4px;
    height: 100%;

    & > div {
      padding: 0.5em 1em;
      display: flex;
      flex-direction: column;
    }

    &-context {
      flex: 1;
      background: $app-white;
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
      overflow: hidden;

      &-title {
        padding: 1em 0.5em;
        font-weight: 600;
      }

      &-row {
        display: flex;
        align-items: center;
        padding: 0.5em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
          margin-left: 0.5em;
        }
      }
    }

    &-values {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      background: $app-grey-light;
      font-size: 12px;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
      width: 45%;
      line-height: 3rem;

      .breakdown, .intervention {
        border-top: 1.5px solid lightgray;
      }

      &-indicator {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .value {
        font-weight: 700;
        font-size: 13px;
      }

      &-icon-alert {
        position: absolute;
        right: -15px;
        top: -15px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $app-red;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 2px solid $app-white;
        font-weight: 600;
        font-size: 1.5em;
      }

      &.alert {
        background: $app-red;
        color: $app-white;
        border: 1px solid $app-red;

        .breakdown, .intervention {
          border-top: 1.5px solid white;
        }
      }
    }

    &-no-data {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      width: 45%;
      cursor: default;
      text-align: center;

      .no-data-illu {
        width: 100%;
      }
    }

    &-swipper {
      position: relative;
      padding: 0 !important;

      .icon {
        width: 50px !important;
        height: 50px !important;
        position: absolute;
        right: -15px;
        top: -15px;
        background: $primary-color;
        border-radius: 50%;
        border: 2px solid $app-white;
        color: $app-white;
        font-size: 2em;
        padding: 10px;
      }
    }
  }
</style>
