<template>
  <div class="details-breadcrumb">
    <chips :label="favoriteNomLabel" :list="favorite.nomenclature ? favorite.nomenclature.items : []" icon="tree" />
    <chips :label="favoriteBrandLabel" :list="favorite.brand || []" icon="label" />
    <chips :label="favoriteSupplierLabel" :list="favorite.supplier || []" icon="palet-truck" />
    <chips :label="$t('details.chips.sales')" :value="indicators.sales" icon="cart" />
    <chips :label="$t('details.chips.breakdown')"
           :rate="indicators.breakdownRate"
           :value="indicators.breakdown"
           icon="broken" />
    <chips :label="$t('details.chips.intervention')"
           :rate="indicators.interventionRate"
           :value="indicators.intervention"
           icon="technics" />
  </div>
</template>

<script>

  export default {
    name: 'DetailsBreadcrumb',
    props: {
      favorite: {
        type: Object,
        default: () => ({})
      },
      indicators: {
        type: Object,
        default: () => ({})
      }
    },
    computed: {
      favoriteNomLabel() {
        return this.favorite && this.favorite.nomenclature ? this.favorite.nomenclature.items[0].label : 'Tous les univers'
      },

      favoriteBrandLabel() {
        return this.favorite && this.favorite.brand && this.favorite.brand.length > 0 ? this.favorite.brand[0].label : 'Toutes les marques'
      },

      favoriteSupplierLabel() {
        return this.favorite && this.favorite.supplier && this.favorite.supplier.length > 0 ? this.favorite.supplier[0].label : 'Tous les fournisseurs'
      }
    }
  }
</script>

<style lang="scss" scoped>
  .details-breadcrumb { display: flex; }
</style>
