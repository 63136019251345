<template>
  <div class="cug-search-popup">
    <div v-for="header in columns"
         :key="`th-${header}`"
         :class="[header, 'th']"
         v-html="$t(`details.productsGrid.headers.${header}`)" />
    <div class="close th" @click="$emit('close')">{{ $t('details.productsGrid.headers.close') }}</div>
    <div v-for="col in columns"
         :key="`tr-${col}`"
         :class="[col, 'tr']"
         v-html="formatGridValue(product[col], col)" />
    <div class="blank" />
    <product-reports :reports="product.reports" />
  </div>
</template>

<script>
  import ProductReports from '@/components/details/ProductReports'

  const columns = ['labelNom', 'groupNomenclature', 'sales', 'breakdown', 'breakdownRate', 'breakdownClosed', 'breakdownInProgress', 'intervention', 'interventionRate', 'interventionClosed', 'interventionInProgress', 'supplier']

  export default {
    name: 'CugSearchPopup',
    components: { ProductReports },
    props: {
      product: {
        type: Object,
        default: () => {}
      }
    },

    data() {
      return {
        columns
      }
    },

    methods: {
      formatGridValue(val, col = undefined) {
        const isRate = col === 'breakdownRate' || col === 'interventionRate'
        if (val === null) return 'N/A'
        if (isFinite(val) && (val % 1 !== 0 || isRate)) return `${ val.toFixed(2) }%`
        return val
      }
    }
  }
</script>

<style lang="scss" scoped>
  .cug-search-popup {
    background-color: white;
    box-shadow: -1px 6px 50px -18px rgba(0, 0, 0, 0.75);
    padding: 1rem 4rem 0 4rem;
    position: absolute;
    top: 3.3em;
    display: grid;
    grid-template-columns: 3fr repeat(12, auto);
    width: 100%;
    z-index: 9999999;

    .th { border-bottom: 1px solid $app-grey-light; }

    .th, .tr {
      padding: 1.5rem;
      text-align: right;
    }

    .th, .labelNom {
      font-family: $primary-font--bold;
      font-size: 14px;
      font-weight: 600;
    }

    .tr {
      font-family: $primary-font;
      font-size: 13px;
    }

    .labelNom { text-align: left }

    .close {
      color: $primary-color;
      text-decoration: underline;
      font-size: 14px;
      cursor: pointer;
      font-weight: 500;
    }

    .product-reports {
      grid-column-start: span 13;
    }

    .breakdown, .intervention { border-left: 1px solid #E8E8E8; }

  }
</style>
