<template>
  <div class="v-supplier">
    <div class="back">
      <v-icon size="2em" font-size="2rem" @click="close"> chevron-left</v-icon>
    </div>
    <div class="v-supplier-header">
      <div class="v-supplier-header-left">
        <h3> Fournisseurs </h3>
        <mark v-if="nbSupplierSelected > 0">{{ nbSupplierSelected }}</mark>
      </div>
      <div class="v-supplier-header-right">
        <div>
          <div class="search">
            <div class="search-icon-container ">
              <v-icon size="2.5em">search</v-icon>
            </div>
            <input class="search-input" type="text" @input="searchSupplier">
            <div class="search-type">
              <span :class="{active : typeSearch === 1}" @click="typeSearch = 1">NOM</span>
              <span :class="{active : typeSearch === 2}" @click="typeSearch = 2">CNUF</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="v-supplier-chips">
      <span v-for="(supplier,index) in suppliersSelected" :key="index" class="chip">
        {{ supplier.label }} <v-icon class="icon" @click="removeSupplierSelected(supplier.id)"> x </v-icon>
      </span>
    </div>
    <div class="v-supplier-letters">
      <span v-for="(letter,index) in letters"
            :key="index"
            class="letter"
            :class="{'active' : letter === letterSelected }"
            @click="selectedLetter(letter)"> {{ letter }} </span>
    </div>
    <ul v-if="isReady" class="v-supplier-list-item">
      <li v-for="(supplier,index) in suppliers.result"
          :key="index"
          class="supplier-item"
          :class="{'selected' : isSupplierSelected(supplier.id)}"
          @click="selectedSupplier(supplier)">
        <v-icon size="3em" class="v-check">check</v-icon>
        <span class="label"> {{ supplier.label }} </span>
      </li>
    </ul>
    <div v-if="isReady" class="v-supplier-footer">
      <ul class="paginate">
        <li v-show="nbPage !== 1 && page !== 1" @click="selectedPage(page - 1)">
          <v-icon> chevron-left</v-icon>
        </li>
        <li v-for="index in nbPage"
            v-if="pageIsVisible(index)"
            :key="index"
            :class="{'active' : index === page}"
            @click="selectedPage(index)">
          {{ index }}
        </li>
        <li v-else-if="nbPage > 20 && index === 21"> ...</li>
        <li v-show="nbPage > 1 && page !== nbPage" @click="selectedPage(page + 1)">
          <v-icon> chevron-right</v-icon>
        </li>
      </ul>
      <div class="count-page" @change="changeLimit($event)">
        <span>Résultat par page :
          <select>
            <option value="30">30</option>
            <option value="60">60</option>
            <option value="90">90</option>
            <option value="120">120</option>
          </select>
        </span>
      </div>
    </div>
    <transition name="slideup">
      <button v-if="nbSupplierSelected > 0" class="btn fixed-bottom" @click="validate">Valider</button>
    </transition>
  </div>
</template>

<script>
  import { withLoader }             from '@/common/decorators'
  import { generateAlphabet }       from '@/common/utils'
  import Vue                        from 'vue'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    model: {
      prop: 'value',
      event: 'change'
    },

    props: {
      value: {
        type: Array,
        default: null
      },
      context: {
        type: Array,
        default: null
      }
    },
    data() {
      return {
        hasError: false,
        isReady: false,
        letters: [],
        letterSelected: 'a',
        page: 1,
        limit: 30,
        suppliersSelected: [],
        search: '',
        typeSearch: 1
      }
    },

    computed: {
      ...mapGetters({
        suppliers: 'referential/suppliers'
      }),

      nbPage() {
        return Math.ceil(this.suppliers.totalCountResult / this.limit)
      },

      nbSupplierSelected() {
        return this.suppliersSelected.length
      }
    },

    async created() {

      this.suppliersSelected = this.value || []
      this.letters           = generateAlphabet()
      await this.fetchSuppliers()
    },

    methods: {

      ...mapActions({
        fetchSuppliers$: 'referential/fetchSuppliers'
      }),

      close() {
        this.$emit('close')
      },

      selectedLetter(letter) {
        this.search         = ''
        this.page           = 1
        this.letterSelected = letter
        this.fetchSuppliers()
      },

      selectedPage(page) {
        if (page > 0 && page <= this.nbPage) {
          this.page = page
          this.fetchSuppliers()
        }
      },

      selectedSupplier(supplier) {
        this.isSupplierSelected(supplier.id)
          ? this.removeSupplierSelected(supplier.id)
          : this.nbSupplierSelected < 10 ? this.suppliersSelected.push(supplier) : Vue.toasted.error('Limitation à 10 fournisseurs', { duration: 5000 })
      },

      isSupplierSelected(supplierId) {
        return this.suppliersSelected.some(el => el.id === supplierId)
      },

      removeSupplierSelected(supplierId) {
        this.suppliersSelected = this.suppliersSelected.filter(el => el.id !== supplierId)
      },

      searchSupplier($event) {
        const value         = $event.target.value
        this.search         = value
        this.letterSelected = value === '' ? 'a' : ''
        this.page           = 1
        this.fetchSuppliers()
      },

      changeLimit($event) {
        this.limit = $event.target.value
        this.fetchSuppliers()
      },

      @withLoader
      async fetchSuppliers() {

        const searchNom  = this.typeSearch === 1 ? this.search : ''
        const searchCnuf = this.typeSearch === 2 ? this.search : ''

        try {
          await this.fetchSuppliers$({ letter: this.letterSelected, limit: this.limit, page: this.page, search: searchNom, cnuf: searchCnuf })
          this.hasError = false
          this.isReady  = true
        } catch (err) {
          this.hasError = true
        }
      },

      validate() {
        this.$emit('update-supplier', this.suppliersSelected)
        this.close()
      },

      pageIsVisible(index) {

        if (this.nbPage < 20) return true
        return (index === this.nbPage
          || index === 1
          || index === this.page
          || (index < this.page + 3 && index > this.page - 3))
      }
    }
  }

</script>

<style lang="scss" scoped>

  .v-supplier {
    font-size      : 1.6rem;
    display        : flex;
    flex-direction : column;
    min-height     : 100%;
    width          : 100%;
    user-select    : none;
    padding        : 3rem 5rem;
    position       : relative;

    .back {
      position : absolute;
      left     : 0.8em;
      cursor   : pointer;
    }

    &-header {
      display         : flex;
      justify-content : space-between;
      align-items     : center;

      &-left {
        display : flex;
        h3 {
          display : inline-block;
          margin  : 0;
        }
        mark {
          display       : inline-block;
          margin-left   : 2rem;
          width         : 23px;
          height        : 23px;
          text-align    : center;
          border-radius : 100%;
          background    : $primary-color;
          color         : $app-white;
        }
      }

      &-right {
        width : 30%;
      }
    }

    &-chips {
      display   : flex;
      flex-wrap : wrap;

      .chip {
        margin-right     : 1rem;
        background-color : $primary-color;
        box-shadow       : $shadow-minimal;
        color            : $app-white;
        font-size        : 0.8em;
        border-radius    : 500px;
        padding          : 0.5em 1em;
        margin-top       : 1em;
        white-space      : nowrap;
        transition       : all 150ms ease-in-out;

        & > .icon {
          cursor : pointer;
        }
      }
    }

    &-letters {
      display    : flex;
      flex-wrap  : wrap;
      margin-top : 2rem;

      .letter {
        cursor         : pointer;
        border         : 1px solid $app-grey;
        text-align     : center;
        margin-right   : 1rem;
        border-radius  : 5px;
        padding        : 0.5em;
        margin-top     : 0.3em;
        width          : 40px;
        height         : 40px;
        text-transform : uppercase;
        &.active {
          background   : $primary-color;
          border-color : $primary-color;
          color        : $app-white;
        }
      }
    }

    &-list-item {
      display               : grid;
      justify-content       : space-between;
      grid-template-columns : repeat(auto-fill, 32%);
      margin-top            : 2rem;
      box-shadow            : 0 3px 1em rgba(#000, 0.1);
      font-size             : 1.4rem;

      li {
        position         : relative;
        display          : flex;
        flex-wrap        : nowrap;
        align-items      : center;
        overflow         : hidden;
        cursor           : pointer;
        border-bottom    : 1px solid #f5f5f5;
        background-color : #fff;

        .v-check {
          position         : relative;
          z-index          : 0;
          cursor           : pointer;
          color            : $app-grey;
          background-color : #f5f5f5;
        }

        .label {
          position     : relative;
          flex-grow    : 1;
          padding-left : 1em;
          white-space  : pre-wrap;
          align-self   : stretch;
          display      : flex;
          align-items  : center;

          &:not(.check-all) {
            text-transform : uppercase;
          }
        }

        .icon {
          position    : relative;
          flex-grow   : 0;
          flex-shrink : 0;
        }

        &.leaf {
          .icon {
            color          : transparent;
            pointer-events : none;
          }
        }

        &.something-selected:not(.selected) {
          background-color : transparent !important;

          .v-check {
            background-color : transparent;
          }
        }

        &.selected {
          box-shadow       : 0 3px 6px rgba(#000, 0.05);
          color            : $primary-color;
          background-color : #fff;
          border-color     : #fff;

          .v-check {
            color            : inherit;
            background-color : transparent;

            :global(& svg) {
              stroke-width : 3px;
            }
          }

          .label {
            box-shadow : none;
            border     : none;
          }
          .icon {
            border : none;
          }
        }
      }
    }

    &-footer {
      align-items     : center;
      display         : flex;
      justify-content : flex-end;
      margin-top      : 1em;

      & > * {
        width : 33%;
      }
      .paginate {
        display         : flex;
        justify-content : center;
        text-align      : center;
        li {
          color          : $secondary-color;
          cursor         : pointer;
          text-align     : center;
          padding        : 0.2em;
          margin-top     : 0.3em;
          margin-right   : 0.3em;
          font-size      : 0.9em;
          text-transform : uppercase;
          width          : 25px;
          &.active {
            color : $primary-color;
          }
        }
      }

      .count-page {
        text-align : right;
        color      : $secondary-color;
        font-size  : 0.9em;

        select {
          color     : $secondary-color;
          border    : none;
          font-size : 1em;

          &:focus {
            border  : none;
            outline : 0;
          }
        }
      }
    }
  }

  .search {
    display         : flex;
    justify-content : space-between;
    align-items     : center;
    border          : 1px solid $app-grey-dark;
    border-radius   : 500px;

    &-icon-container {
      flex            : 1;
      display         : flex;
      justify-content : center;
      align-items     : center;
    }
    &-input {
      flex             : 9;
      background-color : transparent;
      border           : none;
      height           : 2em;
      font-size        : 0.9em;
      padding-left     : 0.25em;
      &:focus {
        outline : none;
      }
    }

    ::placeholder {
      color     : $secondary-color;
      font-size : 1em;
    }

    &-type {
      display : flex;
      height  : 2.5em;

      span {
        cursor      : pointer;
        padding     : 0 0.5em;
        display     : flex;
        align-items : center;
        font-size   : 0.8em;
        height      : 100%;
        background  : $app-grey;
        color       : $secondary-color;
        &.active {
          color      : $app-white;
          background : $primary-color;
        }

        &:last-child {
          border-top-right-radius    : 500px;
          border-bottom-right-radius : 500px;
        }
      }

    }
  }

</style>
