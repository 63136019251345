<template>
  <div
    :class="{ 'search-active' : active ,around : isAround}"
    class="search">
    <div class="search-icon-container ">
      <v-icon size="2.5em">search</v-icon>
    </div>
    <input
      v-model="searchInput"
      class="search-input "
      type="text"
      :placeholder="placeholder"
      @input="sendInputEvent"
      @click="setActiveState">
  </div>
</template>

<script>
export default {
  name: 'Search',
  props: {
    isAround: {
      type: Boolean,
      default: true
    },

    placeholder: {
      type: String,
      default: 'Rechercher'
    }
  },
  data() {
    return {
      searchInput: '',
      active: false
    }
  },
  methods: {
    sendInputEvent() {
      this.$emit('search-change', this.searchInput)
    },
    setActiveState() {
      this.active = true
    },
  }
}
</script>

<style lang="scss" scoped>

.search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $app-grey-dark;
  border-radius: 5px;

  &.around{
    border-radius: 500px;
  }

  &-icon-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-input {
    flex: 9;
    background-color: transparent;
    border: none;
    height: 2em;
    font-size: 0.9em;
    padding-left: 0.25em;
    &:focus {
      outline: none;
    }
  }

  ::placeholder{
    color: $secondary-color;
    font-size: 1em;
  }
}

</style>
