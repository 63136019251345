<template>
  <div :style="{color : color}" class="v-loader">
    <div class="box">
      <v-icon size="100%" class="bird">auchan-bird</v-icon>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'VLoader',
    props: {
      color: {
        type: String,
        default: '#FF9652',
        //default: '#fff'
      }
    }
  }
</script>

<style lang="scss" scoped>
  .v-loader {
    position         : fixed;
    z-index          : 9999;
    top              : 0;
    left             : 0;
    width            : 100vw;
    height           : 100vh;
    display          : flex;
    justify-content  : center;
    align-items      : center;
    background-color : rgba(#fff, 0.5);
    backdrop-filter  : blur(5px);

    .box {
      $s : 20rem;
      width  : 1.2 * $s;
      height : $s;
    }

    .bird {
      :nth-child(1) {
        width  : 100% !important;
        height : 100% !important;

        fill             : none;
        stroke           : currentColor;
        stroke-width     : 0.5px;
        stroke-dasharray : 1;
        stroke-linecap   : round;
        animation        : stroke 5s linear forwards infinite;
      }
    }

    .fall {
      position : absolute;
      width    : 100%;
      height   : 100%;
    }

    @keyframes stroke {
      0% {
        stroke-dasharray : 0, 1000;
      }

      40% {
        stroke-dashoffset : 0;
        stroke-dasharray  : 120, 1000;
        fill              : transparent;
      }

      50% {
        stroke-dashoffset : 0;
        stroke-dasharray  : 120, 1000;
        fill              : currentColor;
      }

      60% {
        stroke-dashoffset : 0;
        stroke-dasharray  : 120, 1000;
        fill              : currentColor;
      }

      70% {
        stroke-dashoffset : 0;
        stroke-dasharray  : 120, 1000;
        fill              : transparent;
      }

      100% {
        stroke-dashoffset : -120px;
        stroke-dasharray  : 120, 1000;
      }
    }

    @keyframes item-fall {
      0% {
        top : -10%;
      }
      100% {
        top : 100%;
      }
    }
    @keyframes item-shake {
      0% {
        transform : translateX(0px);
      }
      50% {
        transform : translateX(80px);
      }
      100% {
        transform : translateX(0px);
      }
    }
    .item {
      position                          : fixed;
      top                               : -10%;
      z-index                           : 9999;
      -webkit-user-select               : none;
      -moz-user-select                  : none;
      -ms-user-select                   : none;
      user-select                       : none;
      cursor                            : default;
      -webkit-animation-name            : item-fall, item-shake;
      -webkit-animation-duration        : 10s, 3s;
      -webkit-animation-timing-function : linear, ease-in-out;
      -webkit-animation-iteration-count : infinite, infinite;
      -webkit-animation-play-state      : running, running;
      animation-name                    : item-fall, item-shake;
      animation-duration                : 5s, 3s;
      animation-timing-function         : linear, ease-in-out;
      animation-iteration-count         : infinite, infinite;
      animation-play-state              : running, running;
    }
    .item:nth-of-type(0) {
      left                    : 1%;
      -webkit-animation-delay : 0s, 0s;
      animation-delay         : 0s, 0s;
    }
    .item:nth-of-type(1) {
      left                    : 10%;
      -webkit-animation-delay : 1s, 1s;
      animation-delay         : 1s, 1s;
    }
    .item:nth-of-type(2) {
      left                    : 20%;
      -webkit-animation-delay : 6s, 0.5s;
      animation-delay         : 6s, 0.5s;
    }
    .item:nth-of-type(3) {
      left                    : 30%;
      -webkit-animation-delay : 4s, 2s;
      animation-delay         : 4s, 2s;
    }
    .item:nth-of-type(4) {
      left                    : 40%;
      -webkit-animation-delay : 2s, 2s;
      animation-delay         : 2s, 2s;
    }
    .item:nth-of-type(5) {
      left                    : 50%;
      -webkit-animation-delay : 8s, 3s;
      animation-delay         : 8s, 3s;
    }
    .item:nth-of-type(6) {
      left                    : 60%;
      -webkit-animation-delay : 6s, 2s;
      animation-delay         : 6s, 2s;
    }
    .item:nth-of-type(7) {
      left                    : 70%;
      -webkit-animation-delay : 2.5s, 1s;
      animation-delay         : 2.5s, 1s;
    }
    .item:nth-of-type(8) {
      left                    : 80%;
      -webkit-animation-delay : 1s, 0s;
      animation-delay         : 1s, 0s;
    }
    .item:nth-of-type(9) {
      left                    : 90%;
      -webkit-animation-delay : 3s, 1.5s;
      animation-delay         : 3s, 1.5s;
    }
  }
</style>
